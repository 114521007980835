import { WindmillContext } from "@windmill/react-ui";
import { ConfigProvider, theme } from "antd";
import enUS from "antd/locale/en_US";
import svSE from "antd/locale/sv_SE";
import Cookies from "js-cookie";
import React, { lazy, useContext } from "react";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import PrivateRoute from "./components/login/PrivateRoute";
import { ToastContainer } from "./utils/toast";

const Layout = lazy(() => import("./layout/Layout"));
const Login = lazy(() => import("./pages/Login"));
const SignUp = lazy(() => import("./pages/SignUp"));
const ForgetPassword = lazy(() => import("./pages/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));

const App = () => {
  const { mode } = useContext(WindmillContext);

  const currentLanguageCode = Cookies.get("i18next") || "sv";

  return (
    <>
      <ConfigProvider
        locale={currentLanguageCode === "sv" ? svSE : enUS}
        theme={{
          algorithm:
            mode === "dark" ? theme.darkAlgorithm : theme.defaultAlgorithm,
          token: {
            colorPrimary: "#007533",
          },
        }}
      >
        <ToastContainer />
        <Router>
          <AccessibleNavigationAnnouncer />
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/signup" component={SignUp} />
            <Route path="/forgot-password" component={ForgetPassword} />
            <Route path="/reset-password/:token" component={ResetPassword} />

            <PrivateRoute>
              <Route path="/" component={Layout} />
            </PrivateRoute>
            <Redirect exact from="/" to="/login" />
          </Switch>
        </Router>
      </ConfigProvider>
    </>
  );
};

export default App;
