import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import LoginServices from "services/LoginService";
import { notifyError } from "utils/toast";
const PrivateRoute = ({ children, ...rest }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const code = queryParams.get("code");
  const [loggedIn, setLoggedIn] = useState(false);
  const [mount, setMount] = useState(false);
  useEffect(() => {
    async function setToken() {
      if (code) {
        try {
          const x = await LoginServices.setToken(code);
          if (!x.access_token) throw new Error("Could not login");
          Cookies.set("access_token", x.access_token, { expires: 3600 });
          Cookies.set("refresh_token", x.refresh_token, { expires: 3600 });
          setMount(true);
          setLoggedIn(true);
        } catch (err) {
          notifyError(
            err
              ? err?.response?.data?.apiError ?? err?.response?.data?.message
              : err.message
          );
          window.location.href = "/login";
        }
      } else {
        setMount(true);
      }
    }
    setToken();
  }, [code]);
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (mount) {
          return loggedIn || Cookies.get("access_token") ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          );
        }
      }}
    />
  );
};

export default PrivateRoute;
