export const translationEn = {
  "Invoice Type": "Invoice Type",
  "Cost Center": "Cost Center",
  NoxFinans: "NoxFinans",
  TermsOfPayment: "Terms of Payment",
  WayOfDelivery: "Way of Delivery",
  super: "Super Admin",
  Message: "Message",

  "This field is required": "This field is required",
  Submit: "Submit",
  Save: "Save",
  "You must provide your fullname": "You must provide your fullname",
  "You must provide your email": "You must provide your email",
  "Account Settings": "Account Settings",
  "Account Details": "Account Details",
  Fullname: "Fullname",
  Email: "Email",

  "Master Log": "Master Log",
  Timestamp: "Timestamp",
  "User ID": "User ID",
  "IP Address": "IP Address",
  "Request Path": "Request Path",
  "Request Method": "Request Method",
  "Request Body": "Request Body",

  "Delivery Date": "Delivery Date",
  "Order Date": "Order Date",
  "Order Type": "Order Type",
  Sent: "Sent",
  Customer: "Customer",
  DeliveryState: "Delivery State",
  "Complete Manually": "Complete Manually",
  "Manually Complete": "Manually Complete",
  Dropship: "Dropship",
  Voided: "Voided",
  "Order Value": "Order Value",
  "Delivery Address": "Delivery Address",
  "Supplier Address": "Supplier Address",
  "Customer Email": "Customer Email",
  "Supplier Email": "Supplier Email",
  Note: "Note",

  Administrationsavgift: "Administration Fee",
  AdministrationFeeVAT: "Administration Fee VAT",
  Address1: "Address Line 1",
  Address2: "Address Line 2",
  Saldo: "Balance",
  BasisTaxReduction: "Basis Tax Reduction",
  City: "City",
  Kommentarer: "Comments",
  ContractReference: "Contract Reference",
  ContributionPercent: "Contribution Percentage",
  ContributionValue: "Contribution Value",
  Land: "Country",
  Kostnadsställe: "Cost Center",
  Kredit: "Credit",
  CreditInvoiceReference: "Credit Invoice Reference",
  Valuta: "Currency",
  Valutakurs: "Exchange Rate",
  Valutaenhet: "Currency Unit",
  Kundnamn: "Customer Name",
  CustomerNumber: "Customer Number",
  DeliveryAddress1: "Delivery Address Line 1",
  DeliveryAddress2: "Delivery Address Line 2",
  DeliveryCity: "Delivery City",
  DeliveryCountry: "Delivery Country",
  DeliveryDate: "Delivery Date",
  DeliveryName: "Delivery Name",
  DeliveryZipCode: "Delivery Zip Code",
  DocumentNumber: "Document Number",
  Förfallodatum: "Due Date",
  ExternalInvoiceReference1: "External Invoice Reference 1",
  ExternalInvoiceReference2: "External Invoice Reference 2",
  Frakt: "Freight",
  FreightVAT: "Freight VAT",
  Gross: "Gross",
  DATUM: "Date",
  InvoicePeriodStart: "Invoice Period Start",
  InvoicePeriodEnd: "Invoice Period End",
  InvoiceReference: "Invoice Reference",
  InvoiceType: "Invoice Type",
  Language: "Language",
  Net: "Net",
  OfferReference: "Offer Reference",
  OrderReference: "Order Reference",
  OrganisationNumber: "Organization Number",
  Vår: "Our",
  PaymentWay: "Payment Way",
  Phone1: "Phone 1",
  Phone2: "Phone 2",
  PriceList: "Price List",
  PrintTemplate: "Print Template",
  Projekt: "Project",
  OutboundDate: "Outbound Date",
  Remarks: "Remarks",
  Reminders: "Reminders",
  RoundOff: "Round Off",
  TermsOfDelivery: "Terms of Delivery",
  Betalningsvillkor: "Payment Terms",
  TimeBasisReference: "Time Basis Reference",
  Totalt: "Total",
  TotalToPay: "Total to Pay",
  TotalVAT: "Total VAT",
  Leveranssätt: "Delivery Method",
  YourOrderNumber: "Your Order Number",
  Din: "Your",
  ZipCode: "Zip Code",
  Redovisningsmetod: "Accounting Method",
  TaxReductionType: "Tax Reduction Type",

  AdministrationFee: "Administration Fee",
  Comments: "Comments",
  CostCenter: "Cost Center",
  CurrencyRate: "Currency Rate",
  CurrencyUnit: "Currency Unit",
  DueDate: "Due Date",
  ExternalInvoiceNumber: "External Invoice Number",
  ExternalInvoiceSeries: "External Invoice Series",
  Freight: "Freight",
  GivenNumber: "Given Number",
  InvoiceNumber: "Invoice Number",
  OCR: "OCR",
  OurReference: "Our Reference",
  RoundOffValue: "Round-Off Value",
  SupplierNumber: "Supplier Number",
  SupplierName: "Supplier Name",
  YourReference: "Your Reference",
  VATType: "VAT Type",
  SalesType: "Sales Type",
  AccountingMethod: "Accounting Method",

  Done: "Done",
  Cancel: "Cancel",
  Bookkeep: "Bookkeep",
  Details: "Details",
  Credit: "Credit",
  ID: "NO",
  Authorizer: "Authorizer",
  Balance: "Balance",
  Booked: "Booked",
  "Due Date": "Due Date",
  "Invoice Date": "Invoice Date",
  "Invoice Number": "Invoice Number",
  Project: "Project",
  Supplier: "Supplier",
  Currency: "Currency",
  Action: "Action",
  "Customer Orders": "Customer Orders",
  "Customer Invoice": "Customer Invoice",
  "Purchase Orders": "Purchase Orders",
  "Supplier Invoice": "Supplier Invoice",
  Admin: "Admin",
  admin: "Admin",
  Registration: "Registration",
  "Last Login": "Last Login",
  Role: "Role",
  "Account Manager": "Account Manager",
  "Purchase Manager": "Purchase Manager",
  Banned: "Banned",
  "Super Admin": "Super Admin",
  "Copied to clipboard": "Copied to clipboard",

  // DashBoard
  DashboardOverview: "Dashboard Overview",
  TodayOrder: "Today Orders",
  YesterdayOrder: "Yesterday Orders",
  ThisMonth: "This Month",
  AllTimeSales: "All-Time Sales",
  TotalOrder: "Total Orders",
  OrderPending: "Orders Pending",
  OrderProcessing: "Orders Processing",
  OrderDelivered: "Orders Delivered",
  POSOrders: "POS Orders",
  OnlineOrders: "Online Orders",
  WeeklySales: "Weekly Sales",
  BestSellingProducts: "Best Selling Products",
  Cash: "Cash",
  Card: "Card",

  // dashBoard Chart
  ConversionsThisYear: "Conversions This Year",
  ConversionsThisWeek: "Conversions This Week",
  FreshVegetable: "Fresh Vegetable",
  TopRevenueProduct: "Top Revenue Product",

  //Line Chart
  Sales: "Sales",

  //Recent Order Table
  RecentOrder: "Recent Order",
  OrderTime: "Order Time",
  DeliveryAddress: "Delivery Address",
  Phone: "Phone",
  Orderamount: "Order amount",

  // Side Menu
  Dashboard: "Dashboard",
  Setting: "Settings",
  Catalog: "Catalog",
  Products: "Products",
  SideMenuCategory: "Category",
  Attributes: "Attributes",
  Attribute: "Attribute",
  Coupons: "Coupons",
  OurStaff: "Our Staff",
  Customers: "Customers",
  International: "International",
  Localization: "Localization",
  Locations: "Locations",
  Taxes: "Taxes",
  Translations: "Translations",
  ViewStore: "Online Store",
  Shop: "View your Store ",
  StoreHome: "Shop Home",
  StoreSetting: "Settings",
  GlobalSettings: "Global Settings",
  UseCompositionProduct: "Use Composition Product",
  CashiersCanOverrideInuseItems: "Cashiers can override Inuse items",
  EditProfile: "Edit Profile",
  LogOut: "Log Out",
  StockHandlingMethod: "Stock Handling Method",
  Pages: "Pages",

  //Products
  ProductsPage: "Products",
  Category: "Category",
  Price: "Price",
  LowtoHigh: "Low to High",
  HightoLow: "High to Low",
  AddProduct: "Add Product",
  BulkAction: "Bulk Action",
  DropCSVfile: "Drop CSV file",
  Upload: "Upload",
  Download: "Download",
  SearchProduct: "Search by product name",
  Published: "Published",
  Unpublished: "Unpublished",
  StatusSelling: "Status - Selling",
  StatusStock: " Status - Out of Stock",
  DateAddedAsc: "Date Added (Asc)",
  DateAddedDesc: "Date Added (Desc)",
  DateUpdatedAsc: "Date Updated (Asc)",
  DateUpdatedDesc: "Date Updated (Desc)",

  //Products Table
  SKUTbl: "SKU",
  ProductNameTbl: "PRODUCT NAME",
  CategoryTbl: "CATEGORY",
  PriceTbl: "price",
  StockTbl: "STOCK",
  StatusTbl: "STATUS",
  VIEW: "VIEW",
  DiscountTbl: "DISCOUNT",
  DetailsTbl: "View",
  PublishedTbl: "PUBLISHED",
  ActionsTbl: "ACTIONS",
  Off: "Off",
  Selling: "Selling",
  SoldOut: "Sold Out",
  Delete: "Delete",
  Edit: "Edit",
  TypeTbl: "Type",
  SelectedCountries: "Selected Countries",

  //Products Drawer
  ThisProductHaveVariants: "Does this product have variants?",
  UpdateProduct: "Update Products",
  UpdateProductDescriptionBulk:
    "Apply changes to the selected products from the list.",
  UpdateProductDescription: "Update products info, combinations and extras.",
  DrawerAddProduct: "Add Product",
  AddProductDescription: "Add your product and necessary information from here",
  ProductImage: "Product Images",
  DragYourImage: "Drag your images here",
  imageFormat: "(Only *.jpeg, *.webp and *.png images will be accepted)",
  ProductID: "Product ID",
  ProductSKU: "Product SKU",
  ProductBarcode: "Product Barcode",
  ProductTitleName: "Product Title/Name",
  ProductSlug: "Product Slug",
  ProductDescription: "Product Description",
  ParentCategory: "Parent Category",
  ChildCategory: "Child Category",
  ProductType: "Product Type",
  Unit: "Unit",
  ShowingIn: "Showing In",
  Store: "Store",
  ProductQuantity: "Product Quantity",
  ProductPrice: "Product Price",
  SalePrice: "Sale Price",
  ProductTag: "Product Tags",
  ProductTagPlaseholder: "Product Tag (Write then press enter to add new tag )",
  AddVariantBtn: "Add Variant",
  CancelBtn: "Cancel",
  UpdateBtn: "Update",
  AddBtn: "Add",
  Selectparentcategory: "Select parent category",
  Selectchildcategory: "Select child category",
  ProductPriceWithTax: "Product Price (tax incl.)",
  ProductPriceWithoutTax: "Product Price",
  ProductPriceTaxExcl: "Product Price (tax excl.)",
  PROFIT: "PROFIT",
  EXCLVAT: "EXCL VAT",
  PRICEINCLVAT: "PRICE INCL VAT",
  TaxRate: "Tax Rate",
  TaxName: "Tax Name",
  AddTaxesBtn: "Add Tax",
  DefaultCategory: "Default Category",
  MasterProduct: "Master Product",
  SellIndividualUnits: "Sell Individual Units",
  Taxexcl: "tax excl.",
  Taxincl: "tax incl.",
  CostPrice: "Cost Price",
  AllowOutStockOrder: "Allow Out Of Stock Order",
  AllowRelatedProductsAndCategories: "Allow Related Products And Categories",
  AllowNotesOnCheckout: "Allow Notes On Checkout",
  ProductMeasurements: "Product Measurements",
  DeleteCombinations: "Delete Combinations",
  Apply: "Apply",
  SelectedCurrencies: "Selected Currencies",
  SelectedLanguages: "Selected Languages",

  //Product Details
  ProductDetails: "Product Details",
  ThisProductShowing: "This product Showing",
  ThisProductHidden: "This product Hidden",
  StockOut: "Stock Out",
  InStock: "In Stock",
  EditProduct: "Edit Product",
  ProductVariantList: "Product Variant List",

  //Products Drawer Combination TBL
  Combination: "Combination",
  Sku: "Sku",
  Barcode: "Barcode",
  PriceTxExcl: "Price(tx excl.)",
  PriceTxIncl: "Price(tx incl.)",
  TotalVol: "Total Vol.",
  QuantityTbl: "Quantity",
  Measurement: "Measurement",
  Change: "Change",
  quart: "quart",
  pint: "pint",
  ounce: "ounce",
  pound: "pound",
  foot: "foot",
  inch: "inch",
  SelectMeasurement: "Select Measurement",

  //Products  Delete Modal
  DeleteModalH2: "Are You Sure! Want to Delete",
  DeleteModalPtag:
    "Do you really want to delete these records? You can't view this in your list anymore if you delete!",
  modalKeepBtn: "No, Keep It",
  modalDeletBtn: "Yes, Delete It",
  Processing: "Processing",

  //Order Invoice Modal
  OrderInvoiceDownload: "Order Invoice & Download",
  PrintReceipt: "Print Receipt",
  DoReStockProducts: "Do you want to Re stock the products?",

  //NotificationModalTwo
  AreSureWantDeleteItem: " Are you sure you want to delete this Item?",
  YesDeleteIt: "Yes, Delete It",

  //Category
  SelectedAttributes: "Selected Attributes",
  CategoryPageTitle: "Category",
  CategorySearchplaceholder: "Search by category type",
  AddCategoryBtn: "Add Category",
  AddCategory: "Add Category",
  CatTbName: "Name",
  CatTbDescription: "Description",
  SearchCategory: "Search by Category name",
  ParentsOnly: "Parents Only",
  All: " All",
  Categoryformenu: "Category for menu",
  SelectCategory: "Select Category",

  //CategoryTable
  catIdTbl: "ID",
  catIconTbl: "Icon",
  catParentTbl: "PARENT",
  catChildrenTbl: "CHILDREN",
  catTypeTbl: "TYPE",
  catPublishedTbl: "PUBLISHED",
  catActionsTbl: "ACTIONS",
  VALUES: "VALUES",

  //Category Drawer
  UpdateCategory: "Update Category",
  UpdateCategoryDescription:
    "Updated your Product category and necessary information from here",
  AddCategoryTitle: "Add Category",
  AddCategoryDescription:
    "Add your Product category and necessary information from here",
  CategoryIcon: "Category Image",
  ChildCategoryPlaceholder:
    "Child category  (Write then press enter to add new child category )",
  ParentCategoryPlaceholder: "Category title",
  CatName: "Name",
  CatDescription: "Description",

  //Coupons
  CouponspageTitle: "Coupon",
  SelectedCoupon: "Selected Coupon",
  AttributeTitle: "Attributes",
  SearchAttributePlaceholder: "Search by attribute name",
  CouponsAddAttributeBtn: "Add Attribute",
  AddCouponsBtn: "Add Coupon",
  CouponBannerImage: "Coupon Banner Image",
  SearchCoupon: "Search by coupon code/name",

  //Coupons table
  CoupTblId: "Id",
  CoupTblStartDate: "START DATE",
  CoupTblEndDate: "END DATE",
  CoupTblCampaignsName: "CAMPAIGN NAME",
  CoupTblCode: "CODE",
  CoupTblPercentage: "PERCENTAGE",
  CoupTblProductType: "PRODUCT TYPE",
  CoupTblStatus: "STATUS",
  CoupTblActions: "ACTIONS",

  //Coupon Drawer
  UpdateCoupon: "Update Coupon",
  UpdateCouponDescription:
    "Updated your coupon and necessary information from here",
  AddCoupon: "Add Coupon",
  AddCouponDescription: "Add your coupon and necessary information from here",
  CampaignName: " Campaign Name",
  CampaignCode: "Campaign Code",
  CouponValidityTime: "Coupon Validity Time",
  DiscountPercentage: "Discount Percentage",
  MinimumAmount: "Minimum Amount",
  MinimumAmountPlasholder: "Minimum amount required",
  DiscountType: "Discount Type",
  Values: "Values",
  //Attribute table
  Id: "NO",
  AName: "NAME",
  ADisplayName: "DISPLAY NAME",
  AOption: "OPTION",
  APublished: "PUBLISHED",
  Avalues: "VALUES",
  AAction: "ACTION",

  //Attribute Drawer
  UpdateAttribute: "Update Attribute Value",
  UpdateAttributeDesc:
    "Updated your attribute values and necessary information from here",
  AddAttribute: "Add Attribute Value",
  AddAttributeDesc:
    "Add your attribute values and necessary information from here",
  DrawerAttributeTitle: "Attribute Title",
  DisplayName: "Display Name",
  DrawerOptions: "Options",
  DrawerSelecttype: "Select type",
  Variants: "Variants",
  Dropdown: "Dropdown",
  Radio: "Radio",
  AddExtra: "Add Extra",
  SearchExtraName: "Search by Extra name",
  //Extra Drawer
  UpdateExtraValue: "Update Extra Value",
  UpdateExtraDescription:
    "Update your Extra values and necessary information from here",
  AddExtraValue: "Add Extra Value",
  AddExtraDescription:
    "Add your Extra values and necessary information from here",

  //Orders
  OrdersPageTitle: "Orders",
  OrdersSearchPhone: "Search by phone",
  OrderStatus: "Status",
  PageOrderDelivered: "Delivered",
  PageOrderPending: "Pending",
  PageOrderProcessing: "Processing",
  OrderCancel: "Cancel",
  Orderlimits: "Order limits",
  DaysOrders5: "Last 5 days orders",
  DaysOrders7: "Last 7 days orders",
  DaysOrders15: "Last 15 days orders",
  DaysOrders30: "Last 30 days orders",
  DownloadOrdersBtn: "Download All Orders",
  ParkOrders: " Park Orders",
  POSCompleted: "POS Completed",
  SearchByInvoice: "Search By Invoice",
  DeleteAllBarOrders: "Delete All Bar Orders",
  DeleteAllKitchenOrders: " Delete All Kitchen Orders",
  StartDate: "Start Date",
  EndDate: "End Date",

  //customer orders
  CustomerOrderList: "Customer Order List",
  CustomerOrderEmpty: "This Customer have no order Yet!",
  CustomerOrderId: "Order ID",
  CustomerOrderTime: "Time",
  CustomerShippingAddress: "Shipping Address",
  CustomerOrderMethod: "Method",
  CustomerOrderStatus: "Status",
  CustomerOrderAction: "Action",

  //Park Orders
  ParkOrdersTitle: "Park Orders",
  TableName: "Table Name",
  SearchCustomerTableName: "Search Customer / Table Name",

  //Orders Table
  InvoiceNo: "INVOICE NO",
  TimeTbl: "ORDER TIME",
  ShippingAddressTbl: "SHIPPING ADDRESS",
  PhoneTbl: "PHONE",
  MethodTbl: "METHOD",
  AmountTbl: "AMOUNT",
  UserTbl: "USER",
  OderStatusTbl: "STATUS",
  ActionTbl: "ACTION",
  InvoiceTbl: "INVOICE",
  ViewInvoice: "View Invoice",
  CustomerName: "Customer Name",
  OrderNumber: "Order No.",
  SearchOrderNumberCustomer: "Search by Order number or customer",

  //Orders Invoice

  InvoicePageTittle: "Invoice",
  Order: "Order",
  InvoiceStatus: "Status",
  InvoiceDate: "DATE",
  InvoiceTo: "INVOICE TO",
  Sr: "SR.",
  ProductName: "PRODUCT NAME",
  Quantity: "QUANTITY",
  quantity: "Quantity",
  ItemPrice: "ITEM PRICE",
  Amount: "AMOUNT",
  InvoicepaymentMethod: "PAYMENT METHOD",
  ShippingCost: "SHIPPING COST",
  InvoiceDicount: "DISCOUNT",
  InvoiceTotalAmount: "TOTAL AMOUNT",
  DownloadInvoice: "Download Rechnung  ",
  PrintInvoice: "Print Invoice",
  ShippingCostLower: "Shipping Cost",
  VATTotal: "VAT Total",
  GrossTotal: "Gross Total",
  Date: "Date",
  Item: "Item",
  QTY: "QTY",
  // Amount: 'Amount',
  ThankYouMsg: "Thank you for your order. Come Again...!",
  NoofItems: "No of Items",
  BillNo: "Bill No",
  Discount: "DISCOUNT",
  DiscountLower: "Discount",
  TotalAmount: "TOTAL AMOUNT",
  DownloadInvoiceBtn: "Download Invoice",
  OrderHistory: "Order History",
  ShippingMethod: "SHIPPING METHOD",
  ShippingMethodLower: "Shipping Method",
  OrderType: "Order type",
  CategorySorting: "Category Sorting",
  TableInfo: "Table info",
  StatusChanged: "Status changed by",
  Seller: "Seller",
  User: "User",
  user: "User",
  Extras: "Extras",
  Notes: "Notes",
  SubTotal: "SubTotal",
  VAT: "VAT",
  Total: "Total",
  Return: "Return",
  Inuse: "Inuse",
  InuseOrder: "InuseOrder",

  //Returns
  Returns: "Returns",
  SearchCodeName: "Search by code/name",
  SelectClickItemsWantReturn: "Select/Click on the Items want to return",
  CartEmpty: "Cart is empty",
  NoItem: "No items added in your cart.",
  ItemReturned: "Item returned in this bill.",
  ReloadAll: "Reload All",
  DoWantReceiptReturnOrder: "Do you want to receipt for this return order?",
  NoDontReturn: "No, Don t Return",
  ReturnOrder: "Return Order",
  TotalItems: "Total Items",
  SubTotalWithoutVAT: "Sub Total Without VAT",
  TotalCost: "Total Cost",
  ThisOrderAlreadyHadReturnitems: "This Order Already had return items!",

  //Staff

  StaffPageTitle: "All Staff",
  StaffSearchBy: "Search by name/email/phone",
  StaffRole: "Staff Role",
  StaffRoleAdmin: "Admin",
  StaffRoleCeo: "CEO",
  SelectCashiers: "Cashier",
  SelectSuperAdmin: "Super Admin",
  StaffRoleManager: "Manager",
  StaffRoleAccountant: "Accountant",
  StaffRoleDriver: "Driver",
  StaffRoleSecurity: "Security Guard",
  StaffRoleDelivery: "Delivery Person",
  AddStaff: "Add Staff",

  //Staff Table
  StaffIdTbl: "ID",
  StaffNameTbl: "Name",
  StaffEmailTbl: "Email",
  StaffContactTbl: "Contact",
  StaffJoiningDateTbl: "Joining Date",
  StaffRoleTbl: "Role",
  StaffShopStatusTbl: "Shop Status",
  StaffPosStatusTbl: "POS Status",
  StaffActionsTbl: "ACTIONS",

  //Staff Drawer
  UpdateStaff: "Update Staff",
  UpdateStaffdescription: "Updated your staff necessary information from here",
  AddStaffTitle: "Add Staff",
  AddStaffdescription: "Add your staff necessary information from here",
  StaffImage: "Staff Image",
  StaffName: "Name",
  StaffNamePlaceholder: "Staff Name",
  StaffEmail: "Email",
  StaffPassword: "Password",
  StaffContactNumber: "Contact Number",
  StaffJoiningDate: "Joining Date",
  DrawerStaffRole: "Staff Role",
  sorryProductNotFound: "Sorry, There are no products right now.",

  // Customers
  CustomersPage: "Customers",
  CustomersPageSearchPlaceholder: "Search by name/email/phone",
  CustomersId: "Id",
  CustomersJoiningDate: "Joining Date",
  CustomersName: "Name",
  CustomersEmail: "Email",
  CustomersPhone: "Phone",
  CustomersActions: "Actions",

  // Customer Orders List

  CustomerOrderListPageTitle: "Customer Orders List",
  NoOrder: "This Customer have no order Yet!",

  // Customer Orders List Table
  OrderIDTbl: "Order ID",
  OrderTimeTbl: "Time",
  OrderShippingAddressTbl: "ShippingAddress",
  OrderPhoneTbl: "Phone",
  OrderMethodTbl: "Method",
  OrderAmountTbl: "Amount",
  OrderStatusTbl: "Status",
  OrderActionsTbl: "Actions",
  ViewOrder: "View Order",

  // Login Box

  LoginTitle: "Login",
  labelEmail: "Email",
  labelPassword: "Password",
  LoginBtn: "Log in",
  ForgotPassword: "Forgot your password",
  CreateAccount: "Create account",
  Account: "Account",

  //Edit Profile and forget password
  updateProfile: " Update Profile",
  ProfilePicture: "Profile Picture",
  ProfileName: "Name",
  ProfileEmail: "Email",
  ProfileContactNumber: "Contact Number",
  ProfileYourRole: "Your Role",

  //  Create account
  "Login with Fortnox": "Login with Fortnox",
  LoginWithGoogle: "Login With Google",
  LoginWithFacebook: "Login With Facebook",
  CreateAccountTitle: "Create account",
  CreateAccountName: "Name",
  CreateAccountCompanyName: "Company Name",
  CreateAccountEmail: "Email",
  CreateAccountPassword: "Password",
  privacyPolicy: "privacy policy",
  TermAndCondition: "Terms and Conditions",
  Iagree: "I agree to the",
  CreateAccountBtn: "Create Account",
  AlreadyAccount: "Already have an account? Login",
  Welcome: "Welcome to Dashtar!",
  Cookies: "Cookies",
  License: "License",
  Content: "Content Liability",
  Disclaimer: "Disclaimer",
  DisclaimerDes:
    "To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will:1. limit or exclude our or your liability for death or personal injury;2. limit or exclude our or your liability for fraud or fraudulent misrepresentation;3. limit any of our or your liabilities in any way that is not permitted under applicable law; or4. exclude any of our or your liabilities that may not be excluded under applicable law.The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty. As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.",
  ContentDescription:
    "We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us against all claims that is rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.",
  LicenseDescription:
    "Unless otherwise stated, Dashtar and/or its licensors own the intellectual property rights for all material on Dashtar. All intellectual property rights are reserved. You may access this from Dashtar for your own personal use subjected to restrictions set in these terms and conditions.This Agreement shall begin on the date hereof. Our Terms and Conditions were created with the help of the Terms And Conditions Generator. You must not:1. Identifiers (e.g. name, mailing address, email address, phone number, credit/debit card number)2. Characteristics of protected classifications (e.g. gender, age)3. Commercial information (e.g. products or services purchased, purchase history)4. Internet or other electronic network activity (e.g. browse or search history)5. Geo location data (e.g. latitude or longitude)6. Audio, electronic, visual, or similar information (e.g. recording of Guest service calls)7. Inferences drawn from any of the above (e.g. preferences or characteristics)Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. Dashtar does not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of Dashtar,its agents and/or affiliates. Comments reflect the views and opinions of the person who post their views and opinions. To the extent permitted by applicable laws, Dashtar shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.",
  CookiesDescription:
    "We employ the use of cookies. By accessing Dashtar, you agreed to use cookies in agreement with the Dashtar's Privacy Policy. Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.",

  WelcomeDescription:
    "These terms and conditions outline the rules and regulations for the use of Dashtar's Website, located at https://dashter.com/. By accessing this website we assume you accept these terms and conditions. Do not continue to use Dashtar if you do not agree to take all of the terms and conditions stated on this page. The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: 'Client', 'You' and 'Your' refers to you, the person log on this website and compliant to the Company’s terms and conditions. 'The Company', 'Ourselves', 'We', 'Our' and 'Us', refers to our Company. 'Party', 'Parties', or 'Us', refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client’s needs in respect of provision of the Company’s stated services, in accordance with and subject to, prevailing law of Netherlands. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.",

  //Forgot password

  ForgotpasswordTitle: "Forgot password",
  RecoverpasswordBtn: "Recover password",

  //POS Setting
  InvoiceNumberLength: "Invoice Number Length",
  DefaultDateFormat: "Default Date Format",
  PosSetting: "POS Setting",
  StoreDetails: "Store Details",
  ShopLogo: "Shop logo",
  ShopName: "Shop name",
  CompanyName: "Company name",
  VATNumber: "VAT number",
  ShopAddressLine1: "Shop address line 1",
  ShopAddressLine2: "Shop address line 2",
  ShopAddressLine3: "Shop address line 3",
  ContactNumber1: "Contact number 1",
  ContactNumber2: "Contact number 2",
  WebSite: "Web site",
  AnyInfoDisplayinSlip: "Additional info to display in receipt",
  AnyInfoDisplayinSlip1:
    "Any additional info display in the bottom of the receipt",
  Configuration: "Configuration",
  ShowImages: "Show images",
  DefaultPOSCustomer: "Default POS customer",
  EmailInvoiceToAdmin: "Email Invoice To Admin(S)",
  ReceiptSize: "Receipt size (width)",
  PreviewReceipt: "Preview receipt",
  Alloworderingofoutofstockproducts: "Allow ordering of out of stock products",
  EnablePinBeforeAction: "Enable pin before any action",
  Quantitypopupforextras: "Quantity popup for extras",
  ShowPaymentOptions: "Show payment options",
  DefaultPaymentMethod: "Default payment method",
  AllowDiscounts: "Allow discounts",
  PrintSlip: "Print slip",
  EmailSlip: "Email Slip to the Customer",

  OrderTypeCapText: "This will shown in POS product card",
  DefaultTaxRate: "Default tax rate",
  SaveBtn: "Save",
  previous: "Previous",
  Next: "Next",
  PaymentMethods: "Payment methods",
  Combined: "Combined",
  NumberProductShowPOSHomePage: "Number Of Products to Show in POS Home Page",
  ProductSorting: "Product sorting",
  Billing: "Billing",
  NumberOfCategoriestoShowinPOSHomePage:
    "Number Of Categories to Show in POS Home Page",

  ApplyTheCostOfTheHighestDefinedRange:
    "Apply the cost Of the highest defined range",
  AddNewRange: " Add new range",

  ShowStockDetailsinProductPage: "Show stock details in product page",
  ShowStockDetailsInStore: "Show stock details in store (global)",
  ShowLowStockNotification: "Show low stock notification",
  LowStockNotificationValue: "Low stock notification value",
  Poshomepageview: "POS home page view",
  Defaultposcategory: " Default POS category",
  DefaultposcategorySmText:
    "Products in this category will be shown in the POS by default",
  TableNavigation: "Table navigation",
  sorryCategoryNotFound: "Sorry, There are no categories right now.",
  ContactDetails: "Contact Details",
  PostCode: "Post Code",
  Theme: "Theme",
  ICON: "ICON",
  ThemeSmallText: "Selected theme will shown in POS",
  EmailRecipients: "Email Recipients",

  // International (Localization)

  Languages: "Languages",
  Currencies: "Currencies",
  Geolocation: "Geolocation",

  DefaultLanguage: "Default language",
  SetLanguageFromBrowser: "Set language from browser",
  DefaultCountry: "Default country",
  DefaultCurrency: "Default currency",
  TimeZone: "Default time zone",
  LocalUnits: "Local Units",
  WeightUnit: "Weight Unit",
  DistanceUnit: "Distance Unit",
  VolumeUnit: "Volume Unit",
  DimensionUnit: "Dimension Unit",
  globalDescription:
    "You already have child products in the system. Turning this feature off will unpublish them. Would you like to continue ?",
  UnitChangeBoxTitle: "Are you sure want to change this?",
  UnitChangeBoxDiscription:
    "Changing unit system could cause misbehavior of existing products and stocks. Are you sure you want to do this ?",

  YesChangeItBtn: "Yes, Change It",

  InternationalCustom: "International / Custom",
  MetricSystemImperialSystem: "Metric System / Imperial System",

  Custom: "Custom",

  Metric: "Metric",
  Imperial: "Imperial",

  Length: "Length",
  SelectLength: "Select Length",
  Centimeter: "Centimeter (cm)",
  Meter: "Meter (m)",
  Kilometer: "Kilometer (Km)",
  Weight: "Weight",
  SelectWeight: "Select Weight",
  Gram: "Gram (g)",
  Volume: "Volume",
  SelectVolume: "Select Volume",
  Milliliter: "Milliliter (ml)",
  Liter: "Liter (l)",
  Mile: "Mile",
  Yard: "Yard",
  Foot: "Foot",
  Inch: "Inch",
  Ounce: "Ounce",
  Pound: "Pound",
  Gallon: "Gallon",
  Pint: "Pint",
  Quart: "Quart",
  TotalVolume: "Total Volume",
  Width: "Width",
  Height: "Height",
  GenerateVariants: "Generate Variants",
  ClearVariants: "Clear Variants",
  AttributesFeatures: "Attributes & Features",
  AddCombinationsDiscription:
    "Please add combinations or enable your attribute, you first need to create proper attributes and values in",
  AddCombinationsDiscriptionTwo:
    '. When done, you may enter the wanted attributes (like "size" or "color") and their respective values ("XS","red", "all", etc.) in the field below; or simply select them from the right column. Then click on "Generate": it will automatically create all the combinations for you!',
  GenerateExtras: "Generate Extras",
  ClearExtras: "Clear Extras",
  BulkActions: "Bulk Actions",
  // Quantity: 'Quantity',
  SelectedExtra: "Selected Extra",
  // International (Languages)

  LanguagesSr: "SR",
  LanguagesNname: "NAME",
  LanguagesIsoCode: "ISO CODE",
  LanguageCode: "LANGUAGE CODE",
  LanguagesFlag: "FLAG",
  LanguagesPublished: "PUBLISHED",
  LanguagesActions: "ACTIONS",

  AddLanguageName: "Language Name",
  AddLanguagesIsoCode: "Iso Code",
  AddLanguageCode: "Languages Code",
  AddLanguagesFlag: "Flag",
  AddLanguagesPublished: "Published",

  SearchLanguage: "Search by country name and iso code, language code",

  // International (Currencies)
  CurrenciesName: "Name",
  Currencyisocode: "Iso Code",
  CurrenciesSymbol: "Symbol",
  CurrenciesIsoCode: "Iso Code",
  CurrenciesExchangeRate: "Exchange Rate",
  CurrenciesEnabled: "Enabled",
  CurrenciesLiveExchangeRates: "Live Exchange Rates",
  CurrenciesActions: "Actions",
  SearchIsoCode: "Search by iso code",

  // Language Drawer
  AddLanguage: "Add Language",
  AddLanguageText: "Add your Language necessary information from here",
  UpdateLanguage: "Update Language",
  UpdateLanguageText: "Updated your Language necessary information from here",
  LanguageName: "Language Name",

  // Update Drawer
  UpdateCurrency: "Update Currency",
  UpdateCurrencyText:
    "Updated your currency and necessary information from here",
  AddCurrency: "Add Currency",
  AddCurrencyText: "Add your Currency and necessary information from here",

  // International (Locations)
  Countries: "Countries",
  States: "States",
  TaxZone: "Tax Zone",
  ShippingZone: "Shipping Zone",
  SearchBy: "Search by zone name",
  Status: "Status",
  Show: "Show",
  Hide: "Hide",
  NameTbl: "NAME",
  SRTbl: "SR",
  IsoCode: "Iso Code",
  CallPrefix: "Call Prefix",

  Country: "Country",
  Zones: "Zones",
  Zone: "Zone",
  Rates: "Rates",
  SearchByStateNameAndIsoCode: "Search by state name and iso code",
  UpdatedZone: "Update Zone",
  UpdatedZoneText: "Updated your Zone necessary information from here",
  AddZone: "Add Zone",
  AddZoneText: "Add your Zone necessary information from here",
  ZoneName: "Zone Name",
  UpdateCountry: "Update Country",
  UpdateCountryText: "Updated your Country necessary information from here",
  AddCountry: "Add Country",
  AddCountryText: "Add your Country necessary information from here",
  CountryName: "Country Name",
  UpdateState: "Update State",
  UpdateStateText: "Updated your State necessary information from here",
  AddState: "Add State",
  AddStateText: "Add your State necessary information from here",
  StateName: "State Name",
  StateBtn: "State",
  AddTaxZone: "Add Tax Zone",
  AddTaxZoneText: "Add your tax zone necessary information from here",
  UpdateTaxZone: "Update Tax Zone",
  UpdateTaxZoneText: "Updated your tax zone necessary information from here",
  TaxZoneName: "Tax Zone Name",
  State: "State",
  TaxZoneBtn: "Tax Zone",
  UpdateShippingZone: "Update Shipping Zone",
  UpdateShippingZoneText:
    "Updated your Shipping Zone necessary information from here",
  AddShippingZone: "Add Shipping Zone",
  AddShippingZoneText: "Add your Shipping Zone necessary information from here",
  ShippingZoneName: "Shipping Zone Name",
  ShippingRate: "Shipping Rate",
  ShippingZoneBtn: "Shipping Zone",
  AddTaxes: "Add Taxes",
  AddTaxesText: "Add your Taxes and necessary information from here",
  UpdateTaxes: "Update Taxes",
  UpdateTaxesText: "Update your Taxes and necessary information from here",
  TaxesBtn: "Taxes",
  shippingZoneSearch: "Search by shipping zone name",
  taxNameSearch: "Search by tax name",

  // Store Customization
  HomeSettings: "Home Page Settings",
  SingleSetting: "Single Product Page Setting",
  AboutUsSetting: "About Us",
  PrivacyTCSetting: "Privacy Policy and T&C",
  FAQSetting: "FAQs",
  OffersStting: "Offers",
  ContactUsStting: "Contact Us",

  Received: "Received",
  Delayed: "Delayed",
  "Not sent": "Not sent",

  StoreCustomizationPageTitle: "Store Customizations",
  Header: "Header",
  HeaderContacts: "Header Contacts",
  HeaderText: "Header Text",
  PhoneNumber: "Phone Number",
  HeaderLogo: "Header Logo",
  weAreAvailable: "We are available 24/7, Need help?",
  MainSlider: "Main Slider",
  Slider: "Slider",
  SliderImages: "Slider Images",
  SliderTitle: "Slider Title",
  SliderDescription: "Slider Description",
  SliderButtonName: "Slider Button Name",
  SliderButtonLink: "Slider Button Link",
  Options: "Options",
  LeftRighArrows: "Left and Right Arrows",
  BottomDots: "Bottom Dots",
  Both: "Both",
  DiscountCouponTitle1: "Discount Coupon Code Box",
  DiscountCouponCodeTitle2: "Discount Coupon Code",
  ShowHide: "Show / Hide",
  SuperDiscountActiveCouponCode: "Super Discount Active Coupon Code",
  SliderFullWidth: "Slider Full Width",
  PlaceHolderImage: "Place Holder Image",
  ImagesResolution: "Images resolution 500X400px",

  PromotionBanner: "Promotion Banner",
  EnableThisBlock: "Enable This Block",
  Title: "Title",
  Description: "Description",
  DESCRIPTION: "DESCRIPTION",
  PromotionDescription: "Promotion Description",
  ButtonName: "Button Name",
  ButtonLink: "Button Link",
  FeaturedCategories: "Featured Categories",
  ProductsLimit: "Products Limit",
  PopularProductsTitle: "Popular Products",
  PopularDescription: "Popular Description",
  QuickDeliverySectionTitle: "Quick Delivery Section",
  SubTitle: "Sub Title",
  Image: "Image",
  LatestDiscountedProductsTitle: "Latest Discounted Products",
  GetYourDailyNeedsTitle: "Get Your Daily Needs",
  LatestDiscountDescription: "Latest Discount Description",
  DailyNeedDescription: "Daily Need Description",
  Button1Link: "Button 1 Link",
  Button2Link: "Button 2 Link",
  Button1image: "Button 1 Image",
  Button2image: "Button 2 Image",
  ImageLeft: "Image Left",
  ImageRight: "Image Right",
  FeaturePromoSectionTitle: "Feature Promo Section",
  FreeShipping: "Free Shipping",
  Support: "Support",
  SecurePayment: "Secure Payment",
  LatestOffer: "Latest Offer",
  TopCategory: "Top Category",
  FooterTitle: "Footer",
  Block: "Block",
  Link: "Link",
  FooterLogo: "Footer Logo",
  FooterAddress: "Address",
  FooterPhone: "Phone",
  FooterEmail: "Email",
  FishAndMeat: "Fish & Meat",
  FooterBottomContact: "Footer Bottom Contact Number",
  SocialLinksTitle: "Social Links",
  PaymentMethod: "Payment Method",
  Statement: "Statement",
  SearchStaffName: "Search by open or closing staff name",
  SoftDrinks: "Soft Drinks",
  BabyCare: "Baby Care",
  BeautyAndHealth: "Beauty & Health",
  MyAccount: "My Account",
  MyOrders: "My Orders",
  RecentOrders: "Recent Orders",
  UpdatedProfile: "Updated Profile",
  SocialLinks: "Social Links",

  //Online Store Setting
  OnlineStorePageTitle: "Online Store Setting",
  General: "General",
  MainShopActivity: "Main Shop Activity",
  StripePublicKey: "Stripe Public Key",
  AllowStripe: "Allow Stripe",
  AllowOrderingOfOutOfStock: "Allow Ordering Of Out-Of-Stock Products",
  CustomDomainName: "Custom Domain Name",
  ShopDomain: "Shop Domain",
  Verify: "Verify",
  AllowPaypal: "Allow Paypal",
  PaypalPublicKey: "Paypal Public Key",
  "Purchase calendar": "Purchase calendar",

  // Global Settings

  UseMultipleLanguagesInShop:
    "I'd like to use multiple languages in my shop, admin and POS",
  UseMultipleCurrenciesInShop:
    "I'd like to use multiple currencies in my shop, admin and POS",

  NumberOfImagesPerProduct: "Number of images per product",
  PosView: "POS view",
  WorkingHours: "Working Hours",
  Open: "Open",
  Close: "Close",
  InvoiceNumberStartValue: "Invoice number start value",
  InvoiceNumberLeadingZeros: "Invoice number leading zeros",
  RetailPOS: "Retail POS",
  Restaurant: "Restaurant",

  Shipping: "Shipping",
  AddShipping: "Add Shipping ",
  SearchByShippingName: "Search by shipping name",
  ShippingName: "Shipping Name",
  ShippingDescription: "Shipping Description",
  Logo: "Logo",
  AddShippingText: "Add your Shipping and necessary information from here",
  UpdateShipping: "Update Shipping",
  UpdateShippingText:
    "Update your Shipping and necessary information from here",
  CarrierLogo: "Carrier Logo",

  Reports: "Reports",
  Orders: "Orders",
  Source: "Source",
  SHOP: "SHOP",

  Actions: "Actions",

  Speedgrade: "Speed grade",
  TrackingURL: "Tracking URL",
  Select: "Select",
  ApplyTheCostTheHighestDefinedRange:
    "Apply The Cost Of The Highest Defined Range",
  DisableCarrier: "Disable Carrier",
  InvoiceNumberType: "Invoice number type",
  AddressLine: "Address",
  GlobalContactNumber: "Contact",
  InvoiceCompanyName: "Company Name",
  VatNumber: "Vat Number",
  Vat: "Vat",

  // Menu Editor
  MenuEditor: "Menu Editor",
  Categories: "Categories",
  AboutUs: "About Us",
  ContactUs: "Contact Us",
  Careers: "Careers",
  LatestNews: "Neuesten Nachrichten",
  Offers: "Offers",
  FAQ: "FAQ",
  PrivacyPolicy: "Privacy Policy",
  TermsConditions: "Terms & Conditions",

  // About Page

  PageHeader: "Page Header",
  PageHeaderBg: "Page Header Background",
  PageTitle: "Page Title",
  AboutPageTopContentLeft: "About Page Top Content Left",
  TopTitle: "Top Title",
  TopDescription: "Top Description",
  BoxOneTitle: "Box One Title",
  BoxOneSubtitle: "Box One Subtitle",
  BoxOneDescription: "Box One Description",
  BoxTwoTitle: "Box Two Title",
  BoxTwoSubtitle: "Box Two Subtitle",
  BoxTwoDescription: "Box Two Description",
  PageTopContentRight: "Page Top Content Right",
  TopContentRightImage: "Top Content Right Image",
  RightBox: "Right Box",
  MiddleContentSection: "Content Section",
  MiddleDescriptionOne: "First Paragraph",
  MiddleDescriptionTwo: "Second Paragraph",
  MiddleContentImage: "Content Image",
  OurFounder: "Our Team",
  OurFounderTitle: "Our Team Title",
  OurFounderDescription: "Our Team Description",
  OurFounderOneImage: "Our Team One Image",
  OurFounderOneTitle: "Our Team One Title",
  OurFounderOneSubTitle: "Our Team One Sub Title",
  OurFounderTwoImage: "Our Team Two Image",
  OurFounderTwoTitle: "Our Team Two Title",
  OurFounderTwoSubTitle: "Our Team Two Sub Title",
  OurFounderThreeImage: "Our Team Three Image",
  OurFounderThreeTitle: "Our Team Three Title",
  OurFounderThreeSubTitle: "Our Team Three Sub Title",
  OurFounderFourImage: "Our Team Four Image",
  OurFounderFourTitle: "Our Team Four Title",
  OurFounderFourSubTitle: "Our Team Four Sub Title",
  OurFounderFiveImage: "Our Team Five Image",
  OurFounderFiveTitle: "Our Team Five Title",
  OurFounderFiveSubTitle: "Our Team Five Sub Title",
  OurFounderSixImage: "Our Team Six Image",
  OurFounderSixTitle: "Our Team Six Title",
  OurFounderSixSubTitle: "Our Team Six Sub Title",
  OurTeam: "Member",

  PrivacyPolicyTermsTitle: "Privacy Policy and T&C",
  PageText: "Page Text",
  LeftImage: "Left Image",
  FaqTitleOne: "Faq Title One",
  FaqDescriptionOne: "Faq Description One",
  FaqTitleTwo: "Faq Title Two",
  FaqDescriptionTwo: "Faq Description Two",
  FaqTitleThree: "Faq Title Three",
  FaqDescriptionThree: "Faq Description Three",
  FaqTitleFour: "Faq Title Four",
  FaqDescriptionFour: "Faq Description Four",
  FaqTitleFive: "Faq Title Five",
  FaqDescriptionFive: "Faq Description Five",
  FaqTitleSix: "Faq Title Six",
  FaqDescriptionSix: "Faq Description Six",
  FaqTitleSeven: "Faq Title Seven",
  FaqDescriptionSeven: "Faq Description Seven",
  FaqTitleEight: "Faq Title Eight",
  FaqDescriptionEight: "Faq Description Eight",
  FAQPageHeader: "FAQs Page Header",
  FaqLeftCol: "FAQs Left Column",

  EmailUs: "Email Us Box",
  EboxTitle: "Title",
  EboxEmail: "Email",
  Eboxtext: "Text",

  CallUs: "Call Us Box",
  CallusboxTitle: "Title",
  CallUsboxPhone: "Phone",
  CallUsboxText: "Text",

  Address: "Address Box",
  AddressboxTitle: "Title",
  AddressboxAddressOne: "Address",
  AddressboxAddressTwo: " Address Two",
  AddressboxAddressThree: " Address Three",

  MidLeftCol: "Middle Left Colum",
  MidLeftImage: "Middle Left Image",

  ContactForm: "Contact Form",
  ContactFormTitle: "Contact Form Title",
  ContactFormDescription: "Contact Form Description",

  FAQS: "FAQs",

  // Reset password

  ResetPassword: "Reset password",
  ConfirmPassword: "Confirm Password",
  Reset: "Reset",
  AlreadyHaveAccount: "Already have an account? Login",

  DoesProductHaveCombinations: " Does this product have combinations?",

  "PURCHASE ORDER": "PURCHASE ORDER",
  REJECTED: "REJECTED",
  SENT: "SENT",
  "NOT SENT": "NOT SENT",
  "SENT NOT REJECTED": "SENT NOT REJECTED",
  DELAYED: "DELAYED",
  RECEIVED: "RECEIVED",
  VOIDED: "VOIDED",
  CURRENT: "CURRENT",
  ALL: "ALL",
  "Internal Reference": "Internal Reference",
  Warehouse: "Warehouse",
  "y/n => j/n": "y/n => j/n",
  "container nr": "container nr",
  "freight company": "freight company",
  "invoice nr": "invoice nr",
  "hs code": "hs code",
  "article nr": "article nr",
  name: "name",
  "ordered qty": "ordered qty",
  unit: "unit",
  "price/unit": "price/unit",
  product: "product",
  freight: "freight",
  custom: "custom",
  "gross profit": "gross profit",
  "gross margin": "gross margin",
  "SUPPLIER INVOICE": "SUPPLIER INVOICE",
  "PO Number": "PO Number",
  "Our reference": "Our reference",
  "Your reference": "Your reference",
  Rate: "Rate",
  "Account title": "Account title",
  "Transaction information": "Transaction information",
  Debit: "Debit",
  "Internal Note": "Internal Note",
  "Show list": "Show list",
  "Delivery Time": "Delivery Time",
  Product: "Product",
  Cost: "Cost",

  ORDER: "ORDER",
  Reserved: "Reserved",
  Delivery: "Delivery",
  "Ship Date": "Ship Date",
  "Order Text": "Order Text",
  "Delivery Done": "Delivery Done",
  "Delivery Information": "Delivery Information",
  "Your order number": "Your order number",
  Name: "Name",
  "Delivery Method": "Delivery Method",
  "Delivery Terms": "Delivery Terms",
  "Type of VAT": "Type of VAT",

  "EU Pallet": "EU Pallet",
  Weight: "Weight",
  "Sea Pallet": "Sea Pallet",
  Date: "Date",
  "ZIP Code": "ZIP Code",
  From: "From",
  To: "To",
  Country: "Country",
  City: "City",
  From: "From",
  To: "To",
  "Are you sure?": "Are you sure?",

  Afghanistan: "Afghanistan",
  Albania: "Albania",
  Algeria: "Algeria",
  Andorra: "Andorra",
  Angola: "Angola",
  "Antigua and Barbuda": "Antigua and Barbuda",
  Argentina: "Argentina",
  Armenia: "Armenia",
  Australia: "Australia",
  Austria: "Austria",
  Azerbaijan: "Azerbaijan",
  Bahamas: "Bahamas",
  Bahrain: "Bahrain",
  Bangladesh: "Bangladesh",
  Barbados: "Barbados",
  Belarus: "Belarus",
  Belgium: "Belgium",
  Belize: "Belize",
  Benin: "Benin",
  Bhutan: "Bhutan",
  Bolivia: "Bolivia",
  "Bosnia and Herzegovina": "Bosnia and Herzegovina",
  Botswana: "Botswana",
  Brazil: "Brazil",
  Brunei: "Brunei",
  Bulgaria: "Bulgaria",
  "Burkina Faso": "Burkina Faso",
  Burundi: "Burundi",
  "Côte d'Ivoire": "Côte d'Ivoire",
  "Cabo Verde": "Cabo Verde",
  Cambodia: "Cambodia",
  Cameroon: "Cameroon",
  Canada: "Canada",
  "Central African Republic": "Central African Republic",
  Chad: "Chad",
  Chile: "Chile",
  China: "China",
  Colombia: "Colombia",
  Comoros: "Comoros",
  "Congo (Congo-Brazzaville)": "Congo (Congo-Brazzaville)",
  "Costa Rica": "Costa Rica",
  Croatia: "Croatia",
  Cuba: "Cuba",
  Cyprus: "Cyprus",
  "Czechia (Czech Republic)": "Czechia (Czech Republic)",
  "Democratic Republic of the Congo": "Democratic Republic of the Congo",
  Denmark: "Denmark",
  Djibouti: "Djibouti",
  Dominica: "Dominica",
  "Dominican Republic": "Dominican Republic",
  Ecuador: "Ecuador",
  Egypt: "Egypt",
  "El Salvador": "El Salvador",
  "Equatorial Guinea": "Equatorial Guinea",
  Eritrea: "Eritrea",
  Estonia: "Estonia",
  Eswatini: "Eswatini",
  Ethiopia: "Ethiopia",
  Fiji: "Fiji",
  Finland: "Finland",
  France: "France",
  Gabon: "Gabon",
  Gambia: "Gambia",
  Georgia: "Georgia",
  Germany: "Germany",
  Ghana: "Ghana",
  Greece: "Greece",
  Grenada: "Grenada",
  Guatemala: "Guatemala",
  Guinea: "Guinea",
  "Guinea-Bissau": "Guinea-Bissau",
  Guyana: "Guyana",
  Haiti: "Haiti",
  "Holy See": "Holy See",
  Honduras: "Honduras",
  Hungary: "Hungary",
  Iceland: "Iceland",
  India: "India",
  Indonesia: "Indonesia",
  Iran: "Iran",
  Iraq: "Iraq",
  Ireland: "Ireland",
  Israel: "Israel",
  Italy: "Italy",
  Jamaica: "Jamaica",
  Japan: "Japan",
  Jordan: "Jordan",
  Kazakhstan: "Kazakhstan",
  Kenya: "Kenya",
  Kiribati: "Kiribati",
  Kuwait: "Kuwait",
  Kyrgyzstan: "Kyrgyzstan",
  Laos: "Laos",
  Latvia: "Latvia",
  Lebanon: "Lebanon",
  Lesotho: "Lesotho",
  Liberia: "Liberia",
  Libya: "Libya",
  Liechtenstein: "Liechtenstein",
  Lithuania: "Lithuania",
  Luxembourg: "Luxembourg",
  Madagascar: "Madagascar",
  Malawi: "Malawi",
  Malaysia: "Malaysia",
  Maldives: "Maldives",
  Mali: "Mali",
  Malta: "Malta",
  "Marshall Islands": "Marshall Islands",
  Mauritania: "Mauritania",
  Mauritius: "Mauritius",
  Mexico: "Mexico",
  Micronesia: "Micronesia",
  Moldova: "Moldova",
  Monaco: "Monaco",
  Mongolia: "Mongolia",
  Montenegro: "Montenegro",
  Morocco: "Morocco",
  Mozambique: "Mozambique",
  "Myanmar (formerly Burma)": "Myanmar (formerly Burma)",
  Namibia: "Namibia",
  Nauru: "Nauru",
  Nepal: "Nepal",
  Netherlands: "Netherlands",
  "New Zealand": "New Zealand",
  Nicaragua: "Nicaragua",
  Niger: "Niger",
  Nigeria: "Nigeria",
  "North Korea": "North Korea",
  "North Macedonia": "North Macedonia",
  Norway: "Norway",
  Oman: "Oman",
  Pakistan: "Pakistan",
  Palau: "Palau",
  "Palestine State": "Palestine State",
  Panama: "Panama",
  "Papua New Guinea": "Papua New Guinea",
  Paraguay: "Paraguay",
  Peru: "Peru",
  Philippines: "Philippines",
  Poland: "Poland",
  Portugal: "Portugal",
  Qatar: "Qatar",
  Romania: "Romania",
  Russia: "Russia",
  Rwanda: "Rwanda",
  "Saint Kitts and Nevis": "Saint Kitts and Nevis",
  "Saint Lucia": "Saint Lucia",
  "Saint Vincent and the Grenadines": "Saint Vincent and the Grenadines",
  Samoa: "Samoa",
  "San Marino": "San Marino",
  "Sao Tome and Principe": "Sao Tome and Principe",
  "Saudi Arabia": "Saudi Arabia",
  Senegal: "Senegal",
  Serbia: "Serbia",
  Seychelles: "Seychelles",
  "Sierra Leone": "Sierra Leone",
  Singapore: "Singapore",
  Slovakia: "Slovakia",
  Slovenia: "Slovenia",
  "Solomon Islands": "Solomon Islands",
  Somalia: "Somalia",
  "South Africa": "South Africa",
  "South Korea": "South Korea",
  "South Sudan": "South Sudan",
  Spain: "Spain",
  "Sri Lanka": "Sri Lanka",
  Sudan: "Sudan",
  Suriname: "Suriname",
  Sweden: "Sweden",
  Switzerland: "Switzerland",
  Syria: "Syria",
  Tajikistan: "Tajikistan",
  Tanzania: "Tanzania",
  Thailand: "Thailand",
  "Timor-Leste": "Timor-Leste",
  Togo: "Togo",
  Tonga: "Tonga",
  "Trinidad and Tobago": "Trinidad and Tobago",
  Tunisia: "Tunisia",
  Turkey: "Turkey",
  Turkmenistan: "Turkmenistan",
  Tuvalu: "Tuvalu",
  Uganda: "Uganda",
  Ukraine: "Ukraine",
  "United Arab Emirates": "United Arab Emirates",
  "United Kingdom": "United Kingdom",
  "United States of America": "United States of America",
  Uruguay: "Uruguay",
  Uzbekistan: "Uzbekistan",
  Vanuatu: "Vanuatu",
  Venezuela: "Venezuela",
  Vietnam: "Vietnam",
  Yemen: "Yemen",
  Zambia: "Zambia",
  Zimbabwe: "Zimbabwe",
};

//////////////////////////// Svenska //////////////////////////////////////////////////////////////////////////////////////////////////////////

export const translationSv = {
  "Invoice Type": "Fakturtyp",
  "Cost Center": "Kostnadsställe",
  NoxFinans: "NoxFinans",
  TermsOfPayment: "Betalningsvillkor",
  WayOfDelivery: "Leveranssätt",
  Message: "Meddelande",

  "This field is required": "Detta fält är obligatoriskt",
  Submit: "Skicka",
  Save: "Spara",
  "You must provide your fullname": "Du måste ange ditt fullständiga namn",
  "You must provide your email": "Du måste ange din e-postadress",
  "Account Settings": "Kontoinställningar",
  "Account Details": "Kontodetaljer",
  Fullname: "Fullständigt namn",
  Email: "E-post",

  "Master Log": "Huvudloggen",
  Timestamp: "Tidsstämpel",
  "User ID": "Användar-ID",
  "IP Address": "IP-adress",
  "Request Path": "Begäranens sökväg",
  "Request Method": "Begäranens metod",
  "Request Body": "Begäranens innehåll",

  "Delivery Date": "Leveransdatum",
  "Order Date": "Orderdatum",
  "Order Type": "Ordertyp",
  Sent: "Skickad",
  Customer: "Kund",
  DeliveryState: "Leveransstatus",
  "Complete Manually": "Slutför manuellt",
  "Manually Complete": "Manuell slutförande",
  Dropship: "Dropship",
  Voided: "Annullerad",
  "Order Value": "Ordervärde",
  "Delivery Address": "Leveransadress",
  "Supplier Address": "Leverantörsadress",
  "Customer Email": "Kundens e-post",
  "Supplier Email": "Leverantörens e-post",
  Note: "Anteckning",

  Administrationsavgift: "Administrationsavgift",
  AdministrationFeeVAT: "Administration avgift moms",
  Address1: "Adress rad 1",
  Address2: "Adress rad 2",
  Saldo: "Saldo",
  BasisTaxReduction: "Grundläggande skattereduktion",
  City: "Stad",
  Kommentarer: "Kommentarer",
  ContractReference: "Kontraktsreferens",
  ContributionPercent: "Bidragsprocent",
  ContributionValue: "Bidragsvärde",
  Land: "Land",
  Kostnadsställe: "Kostnadsställe",
  Kredit: "Kredit",
  CreditInvoiceReference: "Kreditfaktura referens",
  Valuta: "Valuta",
  Valutakurs: "Valutakurs",
  Valutaenhet: "Valutaenhet",
  Kundnamn: "Kundnamn",
  CustomerNumber: "Kundnummer",
  DeliveryAddress1: "Leveransadress rad 1",
  DeliveryAddress2: "Leveransadress rad 2",
  DeliveryCity: "Leverans stad",
  DeliveryCountry: "Leverans land",
  DeliveryDate: "Leveransdatum",
  DeliveryName: "Leveransnamn",
  DeliveryZipCode: "Leverans postnummer",
  DocumentNumber: "Dokumentnummer",
  Förfallodatum: "Förfallodatum",
  ExternalInvoiceReference1: "Extern fakturareferens 1",
  ExternalInvoiceReference2: "Extern fakturareferens 2",
  Frakt: "Frakt",
  FreightVAT: "Frakt moms",
  Gross: "Brutto",
  DATUM: "Datum",
  InvoicePeriodStart: "Fakturaperiod start",
  InvoicePeriodEnd: "Fakturaperiod slut",
  InvoiceReference: "Fakturareferens",
  InvoiceType: "Fakturatype",
  Language: "Språk",
  Net: "Netto",
  OfferReference: "Offertreferens",
  OrderReference: "Orderreferens",
  OrganisationNumber: "Organisationsnummer",
  Vår: "Vår",
  PaymentWay: "Betalningssätt",
  Phone1: "Telefon 1",
  Phone2: "Telefon 2",
  PriceList: "Prislista",
  PrintTemplate: "Utskriftsmall",
  Projekt: "Projekt",
  OutboundDate: "Utgående datum",
  Remarks: "Kommentarer",
  Reminders: "Påminnelser",
  RoundOff: "Avrunda",
  TermsOfDelivery: "Leveransvillkor",
  Betalningsvillkor: "Betalningsvillkor",
  TimeBasisReference: "Tidsbasisreferens",
  Totalt: "Totalt",
  TotalToPay: "Totalt att betala",
  TotalVAT: "Totalt moms",
  Leveranssätt: "Leveranssätt",
  YourOrderNumber: "Ditt ordernummer",
  Din: "Din",
  ZipCode: "Postnummer",
  Redovisningsmetod: "Redovisningsmetod",
  TaxReductionType: "Skattereduktions typ",

  AdministrationFee: "Administrationsavgift",
  Comments: "Kommentarer",
  CostCenter: "Kostnadsställe",
  CurrencyRate: "Valutakurs",
  CurrencyUnit: "Valutaenhet",
  DueDate: "Förfallodatum",
  ExternalInvoiceNumber: "Extern fakturanummer",
  ExternalInvoiceSeries: "Extern fakturaserie",
  Freight: "Frakt",
  GivenNumber: "Givet nummer",
  InvoiceNumber: "Fakturanummer",
  OCR: "OCR",
  OurReference: "Vår referens",
  RoundOffValue: "Avrundningsvärde",
  SupplierNumber: "Leverantörsnummer",
  SupplierName: "Leverantörsnamn",
  YourReference: "Din referens",
  VATType: "Momstyp",
  SalesType: "Försäljningstyp",
  AccountingMethod: "Redovisningsmetod",

  Done: "Klar",
  Cancel: "Avbryt",
  Bookkeep: "Bokföring",
  Details: "Detaljer",
  Credit: "Kredit",
  ID: "NO",
  Authorizer: "Bemyndigad",
  Balance: "Saldo",
  Booked: "Bokad",
  "Due Date": "Förfallodatum",
  "Invoice Date": "Fakturadatum",
  "Invoice Number": "Fakturanummer",
  Project: "Projekt",
  Supplier: "Leverantör",
  Currency: "Valuta",
  Action: "Åtgärd",
  "Customer Orders": "Kundbeställningar",
  "Customer Invoice": "Kundfaktura",
  "Purchase Orders": "Inköpsorder",
  "Supplier Invoice": "Leverantörsfaktura",
  Admin: "Admin",
  admin: "Admin",
  Registration: "Registrering",
  "Last Login": "Senaste inloggning",
  Role: "Roll",
  "Account Manager": "Kontohanterare",
  "Purchase Manager": "Inköpsansvarig",
  Banned: "Avstängd",
  "Super Admin": "Super Admin",
  super: "Super Admin",
  "Copied to clipboard": "Kopierad till urklipp",
  // DashBoard
  DashboardOverview: "Översikt Dashboard",
  TodayOrder: "Dagens beställningar",
  YesterdayOrder: "Gårdagens beställningar",
  ThisMonth: "Denna månad",
  AllTimeSales: "Försäljning totalt",
  TotalOrder: "Totalt antal beställningar",
  OrderPending: "Beställningar väntar",
  OrderProcessing: "Beställningar behandlas",
  OrderDelivered: "Levererade beställningar",
  POSOrders: "POS-beställningar",
  OnlineOrders: "Online-beställningar",
  WeeklySales: "Veckoförsäljning",
  BestSellingProducts: "Bästsäljande produkter",
  Cash: "Kontanter",
  Card: "Kort",

  // dashBoard Chart
  ConversionsThisYear: "Konverteringar i år",
  ConversionsThisWeek: "Konverteringar denna vecka",
  FreshVegetable: "Färska grönsaker",
  TopRevenueProduct: "Produkt med högst intäkt",

  //Line Chart
  Sales: "Försäljning",

  //Recent Order Table
  RecentOrder: "Senaste beställning",
  OrderTime: "Beställningstid",
  DeliveryAddress: "Leveransadress",
  Phone: "Telefon",
  Orderamount: "Beställningsbelopp",

  // Side Menu
  Dashboard: "Dashboard",
  Setting: "Inställningar",
  Catalog: "Katalog",
  Products: "Produkter",
  SideMenuCategory: "Kategori",
  Attributes: "Attribut",
  Attribute: "Attribut",
  Coupons: "Rabattkoder",
  OurStaff: "Vårt personal",
  Customers: "Kunder",
  International: "Internationellt",
  Localization: "Lokalisering",
  Locations: "Platser",
  Taxes: "Skatter",
  Translations: "Översättningar",
  ViewStore: "Onlinebutik",
  Shop: "Visa din butik",
  StoreHome: "Butikens hemsida",
  StoreSetting: "Inställningar",
  GlobalSettings: "Globala inställningar",
  UseCompositionProduct: "Använd sammansättningsprodukt",
  CashiersCanOverrideInuseItems: "Kassörer kan ändra använda objekt",
  EditProfile: "Redigera profil",
  LogOut: "Logga ut",
  StockHandlingMethod: "Lagerhanteringsmetod",
  Pages: "Sidor",

  //Products
  ProductsPage: "Produkter",
  Category: "Kategori",
  Price: "Pris",
  LowtoHigh: "Lågt till högt",
  HightoLow: "Högt till lågt",
  AddProduct: "Lägg till produkt",
  BulkAction: "Massåtgärd",
  DropCSVfile: "Släpp CSV-fil",
  Upload: "Ladda upp",
  Download: "Ladda ner",
  SearchProduct: "Sök efter produktnamn",
  Published: "Publicerad",
  Unpublished: "Ej publicerad",
  StatusSelling: "Status - Säljer",
  StatusStock: "Status - Slut i lager",
  DateAddedAsc: "Datum tillagt (Stigande)",
  DateAddedDesc: "Datum tillagt (Fallande)",
  DateUpdatedAsc: "Datum uppdaterat (Stigande)",
  DateUpdatedDesc: "Datum uppdaterat (Fallande)",

  //Products Table
  SKUTbl: "SKU",
  ProductNameTbl: "PRODUKTNAMN",
  CategoryTbl: "KATEGORI",
  PriceTbl: "Pris",
  StockTbl: "LAGER",
  StatusTbl: "STATUS",
  VIEW: "VISA",
  DiscountTbl: "RABATT",
  DetailsTbl: "Visa",
  PublishedTbl: "PUBLICERAD",
  ActionsTbl: "ÅTGÄRDER",
  Off: "Av",
  Selling: "Säljer",
  SoldOut: "Slut i lager",
  Delete: "Ta bort",
  Edit: "Redigera",
  TypeTbl: "Typ",
  SelectedCountries: "Valda länder",

  //Products Drawer
  ThisProductHaveVariants: "Har denna produkt varianter?",
  UpdateProduct: "Uppdatera produkter",
  UpdateProductDescriptionBulk:
    "Tillämpa ändringar på de valda produkterna från listan.",
  UpdateProductDescription:
    "Uppdatera produktinformation, kombinationer och tillägg.",
  DrawerAddProduct: "Lägg till produkt",
  AddProductDescription: "Lägg till din produkt och nödvändig information här",
  ProductImage: "Produktbilder",
  DragYourImage: "Dra dina bilder hit",
  imageFormat: "(Endast *.jpeg, *.webp och *.png-bilder accepteras)",
  ProductID: "Produkt-ID",
  ProductSKU: "Produkt-SKU",
  ProductBarcode: "Produktstreckkod",
  ProductTitleName: "Produkttitel/Namn",
  ProductSlug: "Produktslug",
  ProductDescription: "Produktbeskrivning",
  ParentCategory: "Överordnad kategori",
  ChildCategory: "Underkategori",
  ProductType: "Produkttyp",
  Unit: "Enhet",
  ShowingIn: "Visas i",
  Store: "Butik",
  ProductQuantity: "Produktantal",
  ProductPrice: "Produktpris",
  SalePrice: "Reapris",
  ProductTag: "Produkttaggar",
  ProductTagPlaseholder:
    "Produkttagg (Skriv och tryck enter för att lägga till ny tagg)",
  AddVariantBtn: "Lägg till variant",
  CancelBtn: "Avbryt",
  UpdateBtn: "Uppdatera",
  AddBtn: "Lägg till",
  Selectparentcategory: "Välj överordnad kategori",
  Selectchildcategory: "Välj underkategori",
  ProductPriceWithTax: "Produktpris (inkl. moms)",
  ProductPriceWithoutTax: "Produktpris",
  ProductPriceTaxExcl: "Produktpris (exkl. moms)",
  PROFIT: "VINST",
  EXCLVAT: "EXKL MOMS",
  PRICEINCLVAT: "PRIS INKL MOMS",
  TaxRate: "Momsats",
  TaxName: "Momsnamn",
  AddTaxesBtn: "Lägg till moms",
  DefaultCategory: "Standardkategori",
  MasterProduct: "Huvudprodukt",
  SellIndividualUnits: "Sälj individuella enheter",
  Taxexcl: "exkl moms",
  Taxincl: "inkl moms",
  CostPrice: "Inköpspris",
  AllowOutStockOrder: "Tillåt beställning av slut i lager",
  AllowRelatedProductsAndCategories:
    "Tillåt relaterade produkter och kategorier",
  AllowNotesOnCheckout: "Tillåt anteckningar vid utcheckning",
  ProductMeasurements: "Produktmått",
  DeleteCombinations: "Ta bort kombinationer",
  Apply: "Tillämpa",
  SelectedCurrencies: "Valda valutor",
  SelectedLanguages: "Valda språk",

  //Product Details
  ProductDetails: "Produktinformation",
  ThisProductShowing: "Denna produkt visas",
  ThisProductHidden: "Denna produkt är dold",
  StockOut: "Slut i lager",
  InStock: "I lager",
  EditProduct: "Redigera produkt",
  ProductVariantList: "Produktvariantlista",

  //Products Drawer Combination TBL
  Combination: "Kombination",
  Sku: "SKU",
  Barcode: "Streckkod",
  PriceTxExcl: "Pris (exkl. moms)",
  PriceTxIncl: "Pris (inkl. moms)",
  TotalVol: "Total volym",
  QuantityTbl: "Antal",
  Measurement: "Mått",
  Change: "Ändra",
  quart: "quart",
  pint: "pint",
  ounce: "ounce",
  pound: "pound",
  foot: "fot",
  inch: "tum",
  SelectMeasurement: "Välj mått",

  //Products Delete Modal
  DeleteModalH2: "Är du säker! Vill du ta bort",
  DeleteModalPtag:
    "Vill du verkligen ta bort dessa poster? Du kommer inte kunna se den i listan längre om du tar bort den!",
  modalKeepBtn: "Nej, behåll den",
  modalDeletBtn: "Ja, ta bort den",
  Processing: "Bearbetning",

  //Order Invoice Modal
  OrderInvoiceDownload: "Beställningsfaktura och nedladdning",
  PrintReceipt: "Skriv ut kvitto",
  DoReStockProducts: "Vill du fylla på produkterna?",

  //NotificationModalTwo
  AreSureWantDeleteItem: "Är du säker på att du vill ta bort den här artikeln?",
  YesDeleteIt: "Ja, ta bort den",

  //Category
  SelectedAttributes: "Valda attribut",
  CategoryPageTitle: "Kategori",
  CategorySearchplaceholder: "Sök efter kategorityp",
  AddCategoryBtn: "Lägg till kategori",
  AddCategory: "Lägg till kategori",
  CatTbName: "Namn",
  CatTbDescription: "Beskrivning",
  SearchCategory: "Sök efter kategorinamn",
  ParentsOnly: "Endast föräldrar",
  All: "Alla",
  Categoryformenu: "Kategori för menyn",
  SelectCategory: "Välj kategori",
  //CategoryTable
  catIdTbl: "ID",
  catIconTbl: "Ikon",
  catParentTbl: "FÖRÄLDER",
  catChildrenTbl: "UNDERKATEGORIER",
  catTypeTbl: "TYP",
  catPublishedTbl: "PUBLICERAD",
  catActionsTbl: "ÅTGÄRDER",
  VALUES: "VÄRDEN",

  //Category Drawer
  UpdateCategory: "Uppdatera kategori",
  UpdateCategoryDescription:
    "Uppdatera produktkategori och nödvändig information härifrån",
  AddCategoryTitle: "Lägg till kategori",
  AddCategoryDescription:
    "Lägg till produktkategori och nödvändig information härifrån",
  CategoryIcon: "Kategoriikon",
  ChildCategoryPlaceholder:
    "Underkategori (Skriv och tryck enter för att lägga till ny underkategori)",
  ParentCategoryPlaceholder: "Kategorititel",
  CatName: "Namn",
  CatDescription: "Beskrivning",

  //Coupons
  CouponspageTitle: "Kupong",
  SelectedCoupon: "Vald kupong",
  AttributeTitle: "Attribut",
  SearchAttributePlaceholder: "Sök efter attributnamn",
  CouponsAddAttributeBtn: "Lägg till attribut",
  AddCouponsBtn: "Lägg till kupong",
  CouponBannerImage: "Kupongbannerbild",
  SearchCoupon: "Sök efter kupongkod/namn",

  "You can't change your own ban": "Du kan inte ändra din egen spärr",
  "This user is super admin, and cannot be banned":
    "Den här användaren är en superadministratör och kan inte spärras",
  "You can't edit your own role": "Du kan inte ändra din egen roll",
  "super role is not assignable": "Superrollen kan inte tilldelas",
  "code missing": "Kod saknas",
  "This user is super admin, and they can only manage their access by themselves":
    "Den här användaren är en superadministratör och kan bara hantera sin egen åtkomst",
  "This user is super admin, and cannot be modified":
    "Den här användaren är en superadministratör och kan inte ändras",

  //Coupons table
  CoupTblId: "ID",
  CoupTblStartDate: "STARTDATUM",
  CoupTblEndDate: "SLUTDATUM",
  CoupTblCampaignsName: "KAMPANNAMN",
  CoupTblCode: "KOD",
  CoupTblPercentage: "PROCENT",
  CoupTblProductType: "PRODUKTYP",
  CoupTblStatus: "STATUS",
  CoupTblActions: "ÅTGÄRDER",

  //Coupon Drawer
  UpdateCoupon: "Uppdatera kupong",
  UpdateCouponDescription:
    "Uppdatera din kupong och nödvändig information härifrån",
  AddCoupon: "Lägg till kupong",
  AddCouponDescription:
    "Lägg till din kupong och nödvändig information härifrån",
  CampaignName: "Kampanjnamn",
  CampaignCode: "Kampanjkod",
  CouponValidityTime: "Kupongens giltighetstid",
  DiscountPercentage: "Rabattprocent",
  MinimumAmount: "Minsta belopp",
  MinimumAmountPlasholder: "Minsta belopp som krävs",
  DiscountType: "Rabatttyp",
  Values: "Värden",

  //Attribute table
  Id: "NO",
  AName: "NAMN",
  ADisplayName: "VISNINGSNAMN",
  AOption: "ALTERNATIV",
  APublished: "PUBLICERAD",
  Avalues: "VÄRDEN",
  AAction: "ÅTGÄRDER",

  //Attribute Drawer
  UpdateAttribute: "Uppdatera attributvärde",
  UpdateAttributeDesc:
    "Uppdatera dina attributvärden och nödvändig information härifrån",
  AddAttribute: "Lägg till attributvärde",
  AddAttributeDesc:
    "Lägg till dina attributvärden och nödvändig information härifrån",
  DrawerAttributeTitle: "Attributtitel",
  DisplayName: "Visningsnamn",
  DrawerOptions: "Alternativ",
  DrawerSelecttype: "Välj typ",
  Variants: "Varianter",
  Dropdown: "Rullgardinsmeny",
  Radio: "Radioknappar",
  AddExtra: "Lägg till extra",
  SearchExtraName: "Sök efter extra namn",

  //Extra Drawer
  UpdateExtraValue: "Uppdatera extra värde",
  UpdateExtraDescription:
    "Uppdatera dina extra värden och nödvändig information härifrån",
  AddExtraValue: "Lägg till extra värde",
  AddExtraDescription:
    "Lägg till dina extra värden och nödvändig information härifrån",

  //Orders
  OrdersPageTitle: "Beställningar",
  OrdersSearchPhone: "Sök efter telefonnummer",
  OrderStatus: "Status",
  PageOrderDelivered: "Levererade",
  PageOrderPending: "Väntande",
  PageOrderProcessing: "Bearbetning",
  OrderCancel: "Avbryt",
  Orderlimits: "Beställningsgränser",
  DaysOrders5: "Senaste 5 dagars beställningar",
  DaysOrders7: "Senaste 7 dagars beställningar",
  DaysOrders15: "Senaste 15 dagars beställningar",
  DaysOrders30: "Senaste 30 dagars beställningar",
  DownloadOrdersBtn: "Ladda ner alla beställningar",
  ParkOrders: "Parkera beställningar",
  POSCompleted: "POS slutfört",
  SearchByInvoice: "Sök efter fakturanummer",
  DeleteAllBarOrders: "Ta bort alla bardrinkbeställningar",
  DeleteAllKitchenOrders: "Ta bort alla köksbeställningar",
  StartDate: "Startdatum",
  EndDate: "Slutdatum",

  //customer orders
  CustomerOrderList: "Kundbeställningslista",
  CustomerOrderEmpty: "Denna kund har inga beställningar än!",
  CustomerOrderId: "Beställnings-ID",
  CustomerOrderTime: "Tid",
  CustomerShippingAddress: "Leveransadress",
  CustomerOrderMethod: "Metod",
  CustomerOrderStatus: "Status",
  CustomerOrderAction: "Åtgärd",

  //Park Orders
  ParkOrdersTitle: "Parkera beställningar",
  TableName: "Bordnamn",
  SearchCustomerTableName: "Sök efter kund/bordnamn",

  //Orders Table
  InvoiceNo: "FAKTURANR",
  TimeTbl: "BESTÄLLNINGSTID",
  ShippingAddressTbl: "LEVERANSADRESS",
  PhoneTbl: "TELEFON",
  MethodTbl: "METOD",
  AmountTbl: "BELOPP",
  UserTbl: "ANVÄNDARE",
  OderStatusTbl: "STATUS",
  ActionTbl: "ÅTGÄRD",
  InvoiceTbl: "FAKTURA",
  ViewInvoice: "Visa faktura",
  CustomerName: "Kundnamn",
  OrderNumber: "Beställningsnummer",
  SearchOrderNumberCustomer: "Sök efter beställningsnummer eller kund",

  //Orders Invoice
  InvoicePageTittle: "Faktura",
  Order: "Beställning",
  InvoiceStatus: "Status",
  InvoiceDate: "DATUM",
  InvoiceTo: "FAKTURATILL",
  Sr: "NR.",
  ProductName: "PRODUKTNAMN",
  Quantity: "ANTAL",
  quantity: "Antal",
  ItemPrice: "STYCKPRIS",
  Amount: "BELOPP",
  InvoicepaymentMethod: "BETALNINGSMETOD",
  ShippingCost: "LEVERANSKOSTNAD",
  InvoiceDicount: "RABATT",
  InvoiceTotalAmount: "TOTALBELOPP",
  DownloadInvoice: "Ladda ner faktura",
  PrintInvoice: "Skriv ut faktura",
  ShippingCostLower: "Leveranskostnad",
  VATTotal: "MOMS Totalt",
  GrossTotal: "Totalt belopp",
  Date: "Datum",
  Item: "Artikel",
  QTY: "ANT",
  ThankYouMsg: "Tack för din beställning. Välkommen åter...!",
  NoofItems: "Antal artiklar",
  BillNo: "Fakturanr",
  Discount: "RABATT",
  DiscountLower: "Rabatt",
  TotalAmount: "TOTALBELOPP",
  DownloadInvoiceBtn: "Ladda ner faktura",
  OrderHistory: "Beställningshistorik",
  ShippingMethod: "LEVERANSSÄTT",
  ShippingMethodLower: "Leveranssätt",
  OrderType: "Beställningstyp",
  CategorySorting: "Sortering av kategori",
  TableInfo: "Bordinformation",
  StatusChanged: "Status ändrad av",
  Seller: "Säljare",
  User: "Användare",
  user: "Användare",
  Extras: "Extra",
  Notes: "Anteckningar",
  SubTotal: "Delsumma",
  VAT: "MOMS",
  Total: "Totalt",
  Return: "Retur",
  Inuse: "Används",
  InuseOrder: "Används-beställning",

  //Returns
  Returns: "Returer",
  SearchCodeName: "Sök efter kod/namn",
  SelectClickItemsWantReturn: "Välj/klicka på de varor du vill returnera",
  CartEmpty: "Varukorgen är tom",
  NoItem: "Inga varor har lagts till i din varukorg.",
  ItemReturned: "Vara returnerad i denna faktura.",
  ReloadAll: "Ladda om alla",
  DoWantReceiptReturnOrder: "Vill du ha kvitto för denna returbeställning?",
  NoDontReturn: "Nej, returnera inte",
  ReturnOrder: "Returnera beställning",
  TotalItems: "Totalt antal varor",
  SubTotalWithoutVAT: "Delsumma utan moms",
  TotalCost: "Total kostnad",
  ThisOrderAlreadyHadReturnitems:
    "Denna beställning har redan returnerade varor!",

  //Staff
  StaffPageTitle: "Alla anställda",
  StaffSearchBy: "Sök efter namn/e-post/telefon",
  StaffRole: "Anställningsroll",
  StaffRoleAdmin: "Admin",
  StaffRoleCeo: "VD",
  SelectCashiers: "Kassör",
  SelectSuperAdmin: "Super Admin",
  StaffRoleManager: "Chef",
  StaffRoleAccountant: "Redovisning",
  StaffRoleDriver: "Förare",
  StaffRoleSecurity: "Säkerhetsvakt",
  StaffRoleDelivery: "Leveranspersonal",
  AddStaff: "Lägg till personal",

  //Staff Table
  StaffIdTbl: "ID",
  StaffNameTbl: "Namn",
  StaffEmailTbl: "E-post",
  StaffContactTbl: "Kontakt",
  StaffJoiningDateTbl: "Anställningsdatum",
  StaffRoleTbl: "Roll",
  StaffShopStatusTbl: "Butiksstatus",
  StaffPosStatusTbl: "POS-status",
  StaffActionsTbl: "Åtgärder",

  //Staff Drawer
  UpdateStaff: "Uppdatera personal",
  UpdateStaffdescription:
    "Uppdatera den nödvändiga informationen om din personal här",
  AddStaffTitle: "Lägg till personal",
  AddStaffdescription:
    "Lägg till den nödvändiga informationen om din personal här",
  StaffImage: "Bild på personal",
  StaffName: "Namn",
  StaffNamePlaceholder: "Personalens namn",
  StaffEmail: "E-post",
  StaffPassword: "Lösenord",
  StaffContactNumber: "Kontaktnummer",
  StaffJoiningDate: "Anställningsdatum",
  DrawerStaffRole: "Anställningsroll",
  sorryProductNotFound: "Tyvärr, det finns inga produkter just nu.",

  // Customers
  CustomersPage: "Kunder",
  CustomersPageSearchPlaceholder: "Sök efter namn/e-post/telefon",
  CustomersId: "ID",
  CustomersJoiningDate: "Anslutningsdatum",
  CustomersName: "Namn",
  CustomersEmail: "E-post",
  CustomersPhone: "Telefon",
  CustomersActions: "Åtgärder",

  // Customer Orders List
  CustomerOrderListPageTitle: "Lista över kundbeställningar",
  NoOrder: "Denna kund har ingen best",

  // Customer Orders List Table
  OrderIDTbl: "Order-ID",
  OrderTimeTbl: "Tid",
  OrderShippingAddressTbl: "Leveransadress",
  OrderPhoneTbl: "Telefon",
  OrderMethodTbl: "Metod",
  OrderAmountTbl: "Belopp",
  OrderStatusTbl: "Status",
  OrderActionsTbl: "Åtgärder",
  ViewOrder: "Visa beställning",

  // Login Box
  LoginTitle: "Logga in",
  labelEmail: "E-post",
  labelPassword: "Lösenord",
  LoginBtn: "Logga in",
  ForgotPassword: "Glömt ditt lösenord",
  Account: "Konto",

  // Edit Profile and forget password
  updateProfile: "Uppdatera profil",
  ProfilePicture: "Profilbild",
  ProfileName: "Namn",
  ProfileEmail: "E-post",
  ProfileContactNumber: "Kontaktnummer",
  ProfileYourRole: "Din roll",

  // Create account
  LoginWithGoogle: "Logga in med Google",
  LoginWithFacebook: "Logga in med Facebook",
  "Login with Fortnox": "Logga in med Fortnox",
  CreateAccountTitle: "Skapa konto",
  CreateAccountName: "Namn",
  CreateAccountCompanyName: "Företagsnamn",
  CreateAccountEmail: "E-post",
  CreateAccountPassword: "Lösenord",
  privacyPolicy: "sekretesspolicy",
  TermAndCondition: "Villkor",
  Iagree: "Jag godkänner",
  CreateAccountBtn: "Skapa konto",
  AlreadyAccount: "Har du redan ett konto? Logga in",
  Welcome: "Välkommen till Dashtar!",
  Cookies: "Cookies",
  License: "Licens",
  Content: "Ansvar för innehåll",
  Disclaimer: "Ansvarsfriskrivning",

  DisclaimerDes:
    "I den utsträckning som tillåts enligt tillämplig lagstiftning utesluter vi alla framställningar, garantier och villkor som rör vår webbplats och användningen av denna webbplats. Ingenting i denna ansvarsfriskrivning kommer att:1. begränsa eller utesluta vårt eller ditt ansvar för dödsfall eller personskada;2. begränsa eller utesluta vårt eller ditt ansvar för bedrägeri eller vilseledande framställningar;3. begränsa något av våra eller dina ansvarsområden på något sätt som inte är tillåtet enligt tillämplig lagstiftning; eller4. utesluta något av våra eller dina ansvarsområden som inte kan uteslutas enligt tillämplig lagstiftning.Begränsningarna och förbuden för ansvar som anges i detta avsnitt och på andra ställen i denna ansvarsfriskrivning: (a) omfattar det föregående stycket; och (b) styr alla ansvarsområden som uppstår enligt ansvarsfriskrivningen, inklusive ansvarsområden som uppstår i avtalsförhållanden, skadeståndsfall och vid brott mot lagstadgade skyldigheter. Så länge webbplatsen och informationen och tjänsterna på webbplatsen tillhandahålls kostnadsfritt, kommer vi inte att vara ansvariga för någon förlust eller skada av något slag.",
  CookiesDescription:
    "Vi använder oss av cookies. Genom att använda Dashtar samtycker du till att använda cookies i enlighet med Dashtars sekretesspolicy. De flesta interaktiva webbplatser använder cookies för att låta oss hämta användarens detaljer vid varje besök. Cookies används av vår webbplats för att möjliggöra funktionaliteten i vissa områden och för att underlätta för besökare på vår webbplats. Vissa av våra partner för affiliate/reklam kan också använda cookies.",

  ContentDescription:
    "Vi kan inte hållas ansvariga för något innehåll som visas på din webbplats. Du samtycker till att skydda och försvara oss mot alla anspråk som uppstår på din webbplats. Ingen länk ska visas på någon webbplats som kan tolkas som förtal, obscen eller olaglig eller som kränker, på annat sätt överträder eller förespråkar överträdelse eller annan kränkning av tredje parts rättigheter. Utan föregående godkännande och skriftligt tillstånd får du inte skapa ramar runt våra webbsidor som ändrar den visuella presentationen eller utseendet på vår webbplats på något sätt.",
  LicenseDescription:
    "Om inte annat anges äger Dashtar och/eller dess licensgivare de immateriella rättigheterna för allt material på Dashtar. Alla immateriella rättigheter är reserverade. Du får använda detta material från Dashtar för din egen personliga användning under de begränsningar som anges i dessa villkor och bestämmelser. Denna överenskommelse träder i kraft från och med dagens datum. Våra villkor och bestämmelser skapades med hjälp av Terms And Conditions Generator. Du får inte: 1. Identifierare (t.ex. namn, postadress, e-postadress, telefonnummer, kredit-/betalkortnummer) 2. Karakteristika hos skyddade klassificeringar (t.ex. kön, ålder) 3. Kommersiell information (t.ex. köpta produkter eller tjänster, köphistorik) 4. Internet- eller annan elektronisk nätverksaktivitet (t.ex. surfhistorik eller sökhistorik) 5. Geografiska platsdata (t.ex. latitud eller longitud) 6. Ljud-, elektronisk, visuell eller liknande information (t.ex. inspelning av gästtjänstsamtal) 7. Slutsatser som dras från något av ovanstående (t.ex. preferenser eller egenskaper) Delar av denna webbplats ger användare möjlighet att skicka in och utbyta åsikter och information i vissa delar av webbplatsen. Dashtar filtrerar inte, redigerar, publicerar eller granskar kommentarer innan de visas på webbplatsen. Kommentarer återspeglar inte åsikterna och åsikterna hos Dashtar, dess agenter och/eller dotterbolag. Kommentarer återspeglar åsikterna och åsikterna hos personen som skickar in sina åsikter och åsikter. Inom den utsträckning som tillåts enligt tillämpliga lagar ska Dashtar inte vara ansvarig för kommentarerna eller för något ansvar, skada eller utgifter som orsakas och/eller drabbas som ett resultat av användning och/eller publicering och/eller framträdande av kommentarerna på denna webbplats.",

  WelcomeDescription:
    "Dessa villkor och bestämmelser beskriver reglerna och föreskrifterna för användningen av Dashtars webbplats, som finns på https://dashter.com/. Genom att komma åt denna webbplats antar vi att du accepterar dessa villkor och bestämmelser. Fortsätt inte att använda Dashtar om du inte godtar samtliga villkor och bestämmelser som anges på denna sida. Följande terminologi gäller för dessa villkor och bestämmelser, integritetsmeddelande och ansvarsfriskrivning samt alla avtal: 'Klient', 'Du' och 'Din' hänvisar till dig, personen som loggar in på denna webbplats och följer företagets villkor och bestämmelser. 'Företaget', 'Vi', 'Oss' och 'Vår' hänvisar till vårt företag. 'Part', 'Parter' eller 'Oss' hänvisar till både klienten och oss. Alla termer avser erbjudandet, accepten och betalningsövervägandet som är nödvändigt för att genomföra processen för att bistå klienten på bästa sätt med uttryckligt syfte att tillgodose klientens behov av företagets angivna tjänster, i enlighet med gällande nederländsk lagstiftning. All användning av ovanstående terminologi eller andra ord i singular, plural, stor eller liten bokstav och/eller han/hon/den/dessa, anses vara utbytbara och därmed referera till samma sak.",

  //Forgot password

  ForgotpasswordTitle: "Glömt lösenord",
  RecoverpasswordBtn: "Återställ lösenord",

  //POS Setting
  InvoiceNumberLength: "Längd på fakturanummer",
  DefaultDateFormat: "Standarddatumformat",
  PosSetting: "POS-inställning",
  StoreDetails: "Butiksuppgifter",
  ShopLogo: "Butikens logotyp",
  ShopName: "Butiksnamn",
  CompanyName: "Företagsnamn",
  VATNumber: "Momsregistreringsnummer",
  ShopAddressLine1: "Butikens adressrad 1",
  ShopAddressLine2: "Butikens adressrad 2",
  ShopAddressLine3: "Butikens adressrad 3",
  ContactNumber1: "Kontaktnummer 1",
  ContactNumber2: "Kontaktnummer 2",
  WebSite: "Webbplats",
  AnyInfoDisplayinSlip: "Ytterligare information att visa på kvittot",
  AnyInfoDisplayinSlip1:
    "All annan information som visas längst ner på kvittot",
  Configuration: "Konfiguration",
  ShowImages: "Visa bilder",
  DefaultPOSCustomer: "Standard-POS-kund",
  EmailInvoiceToAdmin: "E-posta fakturan till administratör(er)",
  ReceiptSize: "Kvittobredd",
  PreviewReceipt: "Förhandsgranska kvitto",
  Alloworderingofoutofstockproducts:
    "Tillåt beställning av produkter som inte finns i lager",
  EnablePinBeforeAction: "Aktivera PIN-kod innan åtgärd",
  Quantitypopupforextras: "Antal popup för extratillval",
  ShowPaymentOptions: "Visa betalningsalternativ",
  DefaultPaymentMethod: "Standardbetalningsmetod",
  AllowDiscounts: "Tillåt rabatter",
  PrintSlip: "Skriv ut kvitto",
  EmailSlip: "E-posta kvitto till kunden",

  OrderTypeCapText: "Detta visas på POS-produktkortet",
  DefaultTaxRate: "Standardmoms",
  SaveBtn: "Spara",
  previous: "Föregående",
  Next: "Nästa",
  PaymentMethods: "Betalningsmetoder",
  Combined: "Kombinerat",
  NumberProductShowPOSHomePage: "Antal produkter som visas på POS startsida",
  ProductSorting: "Produktsortering",
  Billing: "Fakturering",
  NumberOfCategoriestoShowinPOSHomePage:
    "Antal kategorier som visas på POS startsida",

  ApplyTheCostOfTheHighestDefinedRange:
    "Tillämpa kostnaden för den högsta definierade intervallet",
  AddNewRange: "Lägg till nytt intervall",

  ShowStockDetailsinProductPage: "Visa lagerdetaljer på produktsidan",
  ShowStockDetailsInStore: "Visa lagerdetaljer i butiken (globalt)",
  ShowLowStockNotification: "Visa meddelande vid lågt lager",
  LowStockNotificationValue: "Värde för lågt lager-meddelande",
  Poshomepageview: "Visning på POS startsida",
  Defaultposcategory: "Standard-POS-kategori",
  DefaultposcategorySmText:
    "Produkter i denna kategori visas som standard i POS",
  TableNavigation: "Tabellnavigering",
  sorryCategoryNotFound: "Tyvärr, det finns inga kategorier just nu.",
  ContactDetails: "Kontaktuppgifter",

  // International (Localization)

  Languages: "Språk",
  Currencies: "Valutor",
  Geolocation: "Geolocation",

  DefaultLanguage: "Standard språk",
  SetLanguageFromBrowser: "Ange språk från webbläsaren",
  DefaultCountry: "Standardland",
  DefaultCurrency: "Standardvaluta",
  TimeZone: "Standard tidszon",
  LocalUnits: "Lokala enheter",
  WeightUnit: "Viktenhet",
  DistanceUnit: "Avståndsenhet",
  VolumeUnit: "Volymenhet",
  DimensionUnit: "Dimensionenhet",
  globalDescription:
    "Du har redan underprodukter i systemet. Att stänga av den här funktionen kommer att avpublicera dem. Vill du fortsätta?",
  UnitChangeBoxTitle: "Är du säker på att du vill ändra detta?",
  UnitChangeBoxDiscription:
    "Att ändra enhetssystem kan leda till felbeteende för befintliga produkter och lager. Är du säker på att du vill göra detta?",

  YesChangeItBtn: "Ja, ändra det",

  InternationalCustom: "Internationellt / Anpassat",
  MetricSystemImperialSystem: "Metriskt system / Brittiskt system",

  Custom: "Anpassat",

  Metric: "Metrisk",
  Imperial: "Brittisk",

  Length: "Längd",
  SelectLength: "Välj längd",
  Centimeter: "Centimeter (cm)",
  Meter: "Meter (m)",
  Kilometer: "Kilometer (Km)",
  Weight: "Vikt",
  SelectWeight: "Välj vikt",
  Gram: "Gram (g)",
  Volume: "Volym",
  SelectVolume: "Välj volym",
  Milliliter: "Milliliter (ml)",
  Liter: "Liter (l)",
  Mile: "Mile",
  Yard: "Yard",
  Foot: "Foot",
  Inch: "Inch",
  Ounce: "Uns",
  Pound: "Pound",
  Gallon: "Gallon",
  Pint: "Pint",
  Quart: "Quart",
  TotalVolume: "Total volym",
  Width: "Bredd",
  Height: "Höjd",
  GenerateVariants: "Generera varianter",
  ClearVariants: "Rensa varianter",
  AttributesFeatures: "Attribut & Funktioner",
  AddCombinationsDiscription:
    "För att lägga till kombinationer eller aktivera din attributfunktion måste du först skapa rätt attribut och värden i",
  AddCombinationsDiscriptionTwo:
    '. När du är klar kan du ange önskade attribut (som "storlek" eller "färg") och deras respektive värden ("XS", "röd", "alla", etc.) i fältet nedan; eller helt enkelt välja dem från höger kolumn. Klicka sedan på "Generera": det kommer automatiskt att skapa alla kombinationer åt dig!',
  GenerateExtras: "Generera Extra",
  ClearExtras: "Rensa Extra",
  BulkActions: "Massåtgärder",
  // Quantity: 'Antal',
  SelectedExtra: "Valt Extra",
  // International (Languages)

  LanguagesSr: "SR",
  LanguagesNname: "NAMN",
  LanguagesIsoCode: "ISO KOD",
  LanguageCode: "SPRÅKKOD",
  LanguagesFlag: "FLAGGA",
  LanguagesPublished: "PUBLICERAD",
  LanguagesActions: "ÅTGÄRDER",

  PostCode: "Postnummer",
  Theme: "Tema",
  ICON: "IKON",
  ThemeSmallText: "Det valda temat kommer att visas i POS",
  EmailRecipients: "E-postmottagare",

  AddLanguageName: "Språknamn",
  AddLanguagesIsoCode: "Iso-kod",
  AddLanguageCode: "Språkkod",
  AddLanguagesFlag: "Flagga",
  AddLanguagesPublished: "Publicerad",

  SearchLanguage: "Sök efter landsnamn och iso-kod, språkkod",

  // International (Currencies)
  CurrenciesName: "Namn",
  Currencyisocode: "Iso-kod",
  CurrenciesSymbol: "Symbol",
  CurrenciesIsoCode: "Iso-kod",
  CurrenciesExchangeRate: "Växelkurs",
  CurrenciesEnabled: "Aktiverad",
  CurrenciesLiveExchangeRates: "Levande växelkurser",
  CurrenciesActions: "Åtgärder",
  SearchIsoCode: "Sök efter iso-kod",

  // Language Drawer
  AddLanguage: "Lägg till språk",
  AddLanguageText: "Lägg till nödvändig information om ditt språk här",
  UpdateLanguage: "Uppdatera språk",
  UpdateLanguageText: "Uppdatera nödvändig information om ditt språk här",
  LanguageName: "Språknamn",

  // Update Drawer
  UpdateCurrency: "Uppdatera valuta",
  UpdateCurrencyText: "Uppdatera nödvändig information om din valuta här",
  AddCurrency: "Lägg till valuta",
  AddCurrencyText: "Lägg till din valuta och nödvändig information här",

  // International (Locations)
  Countries: "Länder",
  States: "Stater",
  TaxZone: "Skattzon",
  ShippingZone: "Fraktzon",
  SearchBy: "Sök efter zonnamn",
  Status: "Status",
  Show: "Visa",
  Hide: "Dölj",
  NameTbl: "NAMN",
  SRTbl: "SR",
  IsoCode: "Iso-kod",
  CallPrefix: "Riktnummer",

  Country: "Land",
  Zones: "Zoner",
  Zone: "Zon",
  Rates: "Satser",
  SearchByStateNameAndIsoCode: "Sök efter delstatens namn och iso-kod",
  UpdatedZone: "Uppdatera zon",
  UpdatedZoneText: "Uppdatera nödvändig information om din zon här",
  AddZone: "Lägg till zon",
  AddZoneText: "Lägg till nödvändig information om din zon här",
  ZoneName: "Zonnamn",
  UpdateCountry: "Uppdatera land",
  UpdateCountryText: "Uppdatera nödvändig information om ditt land här",
  AddCountry: "Lägg till land",
  AddCountryText: "Lägg till nödvändig information om ditt land här",
  CountryName: "Landets namn",
  UpdateState: "Uppdatera delstat",
  UpdateStateText: "Uppdatera nödvändig information om din delstat här",
  AddState: "Lägg till delstat",
  AddStateText: "Lägg till nödvändig information om din delstat här",
  StateName: "Delstatens namn",
  StateBtn: "Delstat",
  AddTaxZone: "Lägg till skattzon",
  AddTaxZoneText: "Lägg till nödvändig information om din skattzon här",
  UpdateTaxZone: "Uppdatera skattzon",
  UpdateTaxZoneText: "Uppdatera nödvändig information om din skattzon här",
  TaxZoneName: "Skattzonsnamn",
  State: "Delstat",
  TaxZoneBtn: "Skattzon",
  UpdateShippingZone: "Uppdatera fraktzon",
  UpdateShippingZoneText: "Uppdatera nödvändig information om din fraktzon här",
  AddShippingZone: "Lägg till fraktzon",
  AddShippingZoneText: "Lägg till nödvändig information om din fraktzon här",
  ShippingZoneName: "Fraktzonsnamn",
  ShippingRate: "Fraktpris",
  ShippingZoneBtn: "Fraktzon",
  AddTaxes: "Lägg till skatter",
  AddTaxesText: "Lägg till dina skatter och nödvändig information här",
  UpdateTaxes: "Uppdatera skatter",
  UpdateTaxesText: "Uppdatera dina skatter och nödvändig information här",
  TaxesBtn: "Skatter",
  shippingZoneSearch: "Sök efter fraktzonsnamn",
  taxNameSearch: "Sök efter skattens namn",

  // Store Customization
  HomeSettings: "Inställningar för startsidan",
  SingleSetting: "Inställningar för enskild produktsida",
  AboutUsSetting: "Om oss",
  PrivacyTCSetting: "Integritetspolicy och villkor",
  FAQSetting: "Vanliga frågor",
  OffersStting: "Erbjudanden",
  ContactUsStting: "Kontakta oss",

  StoreCustomizationPageTitle: "Butiks anpassning",
  Header: "Header",
  HeaderContacts: "Header Kontakter",
  HeaderText: "Header Text",
  PhoneNumber: "Telefonnummer",
  HeaderLogo: "Header Logotyp",
  weAreAvailable: "Vi är tillgängliga 24/7, Behöver du hjälp?",
  MainSlider: "Huvudbildspel",
  Slider: "Bildspel",
  SliderImages: "Bildspel Bilder",
  SliderTitle: "Bildspel Titel",
  SliderDescription: "Bildspel Beskrivning",
  SliderButtonName: "Bildspel Knappnamn",
  SliderButtonLink: "Bildspel Knapplänk",
  Options: "Alternativ",
  LeftRighArrows: "Vänster och högerpilar",
  BottomDots: "Nedre punkter",
  Both: "Båda",
  DiscountCouponTitle1: "Rabattkupongkodruta",
  DiscountCouponCodeTitle2: "Rabattkupongkod",
  ShowHide: "Visa / Göm",
  SuperDiscountActiveCouponCode: "Super Discount Aktiv Rabattkupongkod",
  SliderFullWidth: "Bildspel Full Bredd",
  PlaceHolderImage: "Platshållarbild",
  ImagesResolution: "Bildupplösning 500X400px",

  PromotionBanner: "Promotion Banner",
  EnableThisBlock: "Aktivera denna block",
  Title: "Titel",
  Description: "Beskrivning",
  DESCRIPTION: "BESKRIVNING",
  PromotionDescription: "Kampanjbeskrivning",
  ButtonName: "Knappnamn",
  ButtonLink: "Knapplänk",
  FeaturedCategories: "Utvalda kategorier",
  ProductsLimit: "Produktbegränsning",
  PopularProductsTitle: "Populära produkter",
  PopularDescription: "Populär beskrivning",
  QuickDeliverySectionTitle: "Snabb leveranssektion",
  SubTitle: "Underrubrik",
  Image: "Bild",
  LatestDiscountedProductsTitle: "Senaste rabatterade produkter",
  GetYourDailyNeedsTitle: "Få dina dagliga behov",
  LatestDiscountDescription: "Senaste rabattbeskrivning",
  DailyNeedDescription: "Daglig behov beskrivning",
  Button1Link: "Knapp 1 Länk",
  Button2Link: "Knapp 2 Länk",
  Button1image: "Knapp 1 Bild",
  Button2image: "Knapp 2 Bild",
  ImageLeft: "Bild till vänster",
  ImageRight: "Bild till höger",
  FeaturePromoSectionTitle: "Funktionspromo-sektion",
  FreeShipping: "Fri frakt",
  Support: "Support",
  SecurePayment: "Säker betalning",
  LatestOffer: "Senaste erbjudandet",
  TopCategory: "Toppkategori",
  FooterTitle: "Sidfot",
  Block: "Block",
  Link: "Länk",
  FooterLogo: "Sidfot Logotyp",
  FooterAddress: "Adress",
  FooterPhone: "Telefon",
  FooterEmail: "E-post",
  FishAndMeat: "Fisk och kött",

  FooterBottomContact: "Kontaktnummer för sidfot",
  SocialLinksTitle: "Sociala länkar",
  PaymentMethod: "Betalningsmetod",
  Statement: "Uttalande",
  SearchStaffName: "Sök efter personalens namn",
  SoftDrinks: "Läskedrycker",
  BabyCare: "Barnvård",
  BeautyAndHealth: "Skönhet och hälsa",
  MyAccount: "Mitt konto",
  MyOrders: "Mina beställningar",
  RecentOrders: "Senaste beställningar",
  UpdatedProfile: "Uppdaterad profil",
  SocialLinks: "Sociala länkar",

  //Online Store Setting
  OnlineStorePageTitle: "Inställningar för onlinebutik",
  General: "Allmänt",
  MainShopActivity: "Huvudbutiksaktivitet",
  StripePublicKey: "Stripe Public Key",
  AllowStripe: "Tillåt Stripe",
  AllowOrderingOfOutOfStock:
    "Tillåt beställning av produkter som är slut i lager",
  CustomDomainName: "Anpassat domännamn",
  ShopDomain: "Butikens domän",
  Verify: "Verifiera",
  AllowPaypal: "Tillåt Paypal",
  PaypalPublicKey: "Paypal Public Key",
  // Global Settings

  UseMultipleLanguagesInShop:
    "Jag vill använda flera språk i min butik, admin och POS",
  UseMultipleCurrenciesInShop:
    "Jag vill använda flera valutor i min butik, admin och POS",

  NumberOfImagesPerProduct: "Antal bilder per produkt",
  PosView: "POS-visning",
  WorkingHours: "Arbetstider",
  Open: "Öppet",
  Close: "Stängt",
  InvoiceNumberStartValue: "Startvärde för fakturanummer",
  InvoiceNumberLeadingZeros: "Förledande nollor i fakturanummer",
  RetailPOS: "Detaljhandels-POS",
  Restaurant: "Restaurang",

  Shipping: "Frakt",
  AddShipping: "Lägg till frakt",
  SearchByShippingName: "Sök efter fraktnamn",
  ShippingName: "Fraktnamn",
  ShippingDescription: "Fraktbeskrivning",
  Logo: "Logotyp",
  AddShippingText: "Lägg till din frakt och nödvändig information härifrån",
  UpdateShipping: "Uppdatera frakt",
  UpdateShippingText: "Uppdatera din frakt och nödvändig information härifrån",
  CarrierLogo: "Fraktbolagslogotyp",

  Reports: "Rapporter",
  Orders: "Beställningar",
  Source: "Källa",
  SHOP: "BUTIK",

  Actions: "Åtgärder",

  Speedgrade: "Hastighetsklass",
  TrackingURL: "Spårnings-URL",
  Select: "Välj",
  ApplyTheCostTheHighestDefinedRange:
    "Tillämpa kostnaden för den högsta definierade intervallen",
  DisableCarrier: "Inaktivera fraktbolag",
  InvoiceNumberType: "Fakturanummertyp",
  AddressLine: "Adress",
  GlobalContactNumber: "Kontaktnummer",
  InvoiceCompanyName: "Företagsnamn för faktura",
  VatNumber: "Momsnummer",
  Vat: "Moms",

  // Menu Editor
  MenuEditor: "Menyredigerare",
  Categories: "Kategorier",
  AboutUs: "Om oss",
  ContactUs: "Kontakta oss",
  Careers: "Karriär",
  LatestNews: "Senaste nytt",
  Offers: "Erbjudanden",
  FAQ: "Vanliga frågor",
  PrivacyPolicy: "Integritetspolicy",
  TermsConditions: "Villkor",

  // About Page

  PageHeader: "Sidhuvud",
  PageHeaderBg: "Bakgrund för sidhuvud",
  PageTitle: "Sidtitel",
  AboutPageTopContentLeft: "Innehåll längst upp på Om-sidan (vänster)",
  TopTitle: "Överskrift",
  TopDescription: "Beskrivning",
  BoxOneTitle: "Ruta 1 - Rubrik",
  BoxOneSubtitle: "Ruta 1 - Underrubrik",
  BoxOneDescription: "Ruta 1 - Beskrivning",
  BoxTwoTitle: "Ruta 2 - Rubrik",
  BoxTwoSubtitle: "Ruta 2 - Underrubrik",
  BoxTwoDescription: "Ruta 2 - Beskrivning",
  PageTopContentRight: "Innehåll längst upp på sidan (höger)",
  TopContentRightImage: "Bild",
  RightBox: "Höger ruta",
  MiddleContentSection: "Innehållssektion",

  MiddleDescriptionOne: "Första stycket",
  MiddleDescriptionTwo: "Andra stycket",
  MiddleContentImage: "Innehållsbild",
  OurFounder: "Vårt team",
  OurFounderTitle: "Vårt team - Rubrik",
  OurFounderDescription: "Vårt team - Beskrivning",
  OurFounderOneImage: "Bild för Vårt team - Medlem 1",
  OurFounderOneTitle: "Vårt team - Medlem 1 - Rubrik",
  OurFounderOneSubTitle: "Vårt team - Medlem 1 - Underrubrik",
  OurFounderTwoImage: "Bild för Vårt team - Medlem 2",
  OurFounderTwoTitle: "Vårt team - Medlem 2 - Rubrik",
  OurFounderTwoSubTitle: "Vårt team - Medlem 2 - Underrubrik",
  OurFounderThreeImage: "Bild för Vårt team - Medlem 3",
  OurFounderThreeTitle: "Vårt team - Medlem 3 - Rubrik",
  OurFounderThreeSubTitle: "Vårt team - Medlem 3 - Underrubrik",
  OurFounderFourImage: "Bild för Vårt team - Medlem 4",
  OurFounderFourTitle: "Vårt team - Medlem 4 - Rubrik",
  OurFounderFourSubTitle: "Vårt team - Medlem 4 - Underrubrik",
  OurFounderFiveImage: "Bild för Vårt team - Medlem 5",
  OurFounderFiveTitle: "Vårt team - Medlem 5 - Rubrik",
  OurFounderFiveSubTitle: "Vårt team - Medlem 5 - Underrubrik",
  OurFounderSixImage: "Bild för Vårt team - Medlem 6",
  OurFounderSixTitle: "Vårt team - Medlem 6 - Rubrik",
  OurFounderSixSubTitle: "Vårt team - Medlem 6 - Underrubrik",
  OurTeam: "Medlem",

  PrivacyPolicyTermsTitle: "Integritetspolicy och Villkor",
  PageText: "Sidtext",
  LeftImage: "Vänster bild",
  FaqTitleOne: "Faq Rubrik 1",
  FaqDescriptionOne: "Faq Beskrivning 1",
  FaqTitleTwo: "Faq Rubrik 2",
  FaqDescriptionTwo: "Faq Beskrivning 2",
  FaqTitleThree: "Faq Rubrik 3",
  FaqDescriptionThree: "Faq Beskrivning 3",
  FaqTitleFour: "Faq Rubrik 4",
  FaqDescriptionFour: "Faq Beskrivning 4",
  FaqTitleFive: "Faq Rubrik 5",
  FaqDescriptionFive: "Faq Beskrivning 5",
  FaqTitleSix: "Faq Rubrik 6",
  FaqDescriptionSix: "Faq Beskrivning 6",
  FaqTitleSeven: "Faq Rubrik 7",
  FaqDescriptionSeven: "Faq Beskrivning 7",
  FaqTitleEight: "Faq Rubrik 8",
  FaqDescriptionEight: "Faq Beskrivning 8",
  FAQPageHeader: "Rubrik för Vanliga frågor",

  FaqLeftCol: "Vänsterkolumn för FAQ",
  EmailUs: "E-post oss-ruta",
  EboxTitle: "Titel",
  EboxEmail: "E-post",
  Eboxtext: "Text",

  CallUs: "Ring oss-ruta",
  CallusboxTitle: "Titel",
  CallUsboxPhone: "Telefon",
  CallUsboxText: "Text",

  Address: "Adressruta",
  AddressboxTitle: "Titel",
  AddressboxAddressOne: "Adress",
  AddressboxAddressTwo: " Adress två",
  AddressboxAddressThree: " Adress tre",

  MidLeftCol: "Vänsterkolumn mitten",
  MidLeftImage: "Bild i vänsterkolumnen i mitten",

  ContactForm: "Kontaktformulär",
  ContactFormTitle: "Titel för kontaktformulär",
  ContactFormDescription: "Beskrivning av kontaktformulär",

  FAQS: "Vanliga frågor",

  // Återställ lösenord

  ResetPassword: "Återställ lösenord",
  ConfirmPassword: "Bekräfta lösenord",
  Reset: "Återställ",
  AlreadyHaveAccount: "Har du redan ett konto? Logga in",

  DoesProductHaveCombinations: "Har den här produkten olika varianter?",

  "PURCHASE ORDER": "INKÖPSORDER",
  REJECTED: "AVVISAD",
  SENT: "SKICKAD",
  "NOT SENT": "EJ SKICKAD",
  "SENT NOT REJECTED": "SKICKAD EJ AVVISAD",
  DELAYED: "FÖRSENAD",
  RECEIVED: "MOTTAGEN",
  VOIDED: "OGILTIG",
  CURRENT: "AKTUELL",
  ALL: "ALLA",
  "Internal Reference": "Intern Referens",
  Warehouse: "Lager",
  "y/n": "j/n",
  "container nr": "CONTAINER NR",
  "freight company": "fraktbolag",
  "invoice nr": "fakturanr",
  "hs code": "HS-kod",
  "article nr": "artikel nr",
  name: "namn",
  "ordered qty": "beställt antal",
  unit: "enhet",
  "price/unit": "pris/enhet",
  product: "produkt",
  freight: "frakt",
  custom: "tull",
  "gross profit": "bruttovinst",
  "gross margin": "bruttomarginal",
  "SUPPLIER INVOICE": "LEVERANTÖRSFAKTURA",
  "PO Number": "Beställningsnummer",
  "Our reference": "Vår referens",
  "Your reference": "Er referens",
  Rate: "Sats",
  "Account title": "Kontots titel",
  "Transaction information": "Transaktionsinformation",
  Debit: "Debitera",
  "Internal Note": "Intern Anteckning",
  "Show list": "Visa lista",
  "Delivery Time": "Leveranstid",
  Product: "Produkt",

  ORDER: "BESTÄLLA",
  Cost: "Kosta",
  Reserved: "Reserverad",
  Delivery: "Leverans",
  "Ship Date": "Leveransdatum",
  "Order Text": "Beställningstext",
  "Delivery Done": "Leverans klar",
  "Delivery Information": "Leveransinformation",
  "Your order number": "Ditt beställningsnummer",
  Name: "namn",
  "Delivery Method": "leverans metod",
  "Delivery Terms": "Leveransvillkor",
  "Type of VAT": "Typ av moms",

  Received: "Mottagen",
  Delayed: "Försenad",
  "Not sent": "El skickad",
  "Purchase calendar": "Köp kalender",

  "EU Pallet": "EU-pall",
  Weight: "Vikt",
  "Sea Pallet": "Havspall",
  Date: "Datum",
  "ZIP Code": "Postnummer",
  From: "Från",
  To: "Till",
  Country: "Land",
  City: "Stad",
  From: "Från",
  To: "Till",
  "Are you sure?": "Är du säker?",
  Afghanistan: "Afghanistan",
  Albania: "Albanien",
  Algeria: "Algeriet",
  Andorra: "Andorra",
  Angola: "Angola",
  "Antigua and Barbuda": "Antigua och Barbuda",
  Argentina: "Argentina",
  Armenia: "Armenien",
  Australia: "Australien",
  Austria: "Österrike",
  Azerbaijan: "Azerbajdzjan",
  Bahamas: "Bahamas",
  Bahrain: "Bahrain",
  Bangladesh: "Bangladesh",
  Barbados: "Barbados",
  Belarus: "Belarus",
  Belgium: "Belgien",
  Belize: "Belize",
  Benin: "Benin",
  Bhutan: "Bhutan",
  Bolivia: "Bolivia",
  "Bosnia and Herzegovina": "Bosnien och Hercegovina",
  Botswana: "Botswana",
  Brazil: "Brasilien",
  Brunei: "Brunei",
  Bulgaria: "Bulgarien",
  "Burkina Faso": "Burkina Faso",
  Burundi: "Burundi",
  "Côte d'Ivoire": "Elfenbenskusten",
  "Cabo Verde": "Cabo Verde",
  Cambodia: "Kambodja",
  Cameroon: "Kamerun",
  Canada: "Kanada",
  "Central African Republic": "Centralafrikanska republiken",
  Chad: "Tchad",
  Chile: "Chile",
  China: "Kina",
  Colombia: "Colombia",
  Comoros: "Komorerna",
  "Congo (Congo-Brazzaville)": "Kongo (Kongo-Brazzaville)",
  "Costa Rica": "Costa Rica",
  Croatia: "Kroatien",
  Cuba: "Kuba",
  Cyprus: "Cypern",
  "Czechia (Czech Republic)": "Tjeckien (Tjeckien)",
  "Democratic Republic of the Congo": "Demokratiska republiken Kongo",
  Denmark: "Danmark",
  Djibouti: "Djibouti",
  Dominica: "Dominica",
  "Dominican Republic": "Dominikanska republiken",
  Ecuador: "Ecuador",
  Egypt: "Egypten",
  "El Salvador": "El Salvador",
  "Equatorial Guinea": "Ekvatorialguinea",
  Eritrea: "Eritrea",
  Estonia: "Estland",
  Eswatini: "Eswatini",
  Ethiopia: "Etiopien",
  Fiji: "Fiji",
  Finland: "Finland",
  France: "Frankrike",
  Gabon: "Gabon",
  Gambia: "Gambia",
  Georgia: "Georgien",
  Germany: "Tyskland",
  Ghana: "Ghana",
  Greece: "Grekland",
  Grenada: "Grenada",
  Guatemala: "Guatemala",
  Guinea: "Guinea",
  "Guinea-Bissau": "Guinea-Bissau",
  Guyana: "Guyana",
  Haiti: "Haiti",
  "Holy See": "Heliga stolen",
  Honduras: "Honduras",
  Hungary: "Ungern",
  Iceland: "Island",
  India: "Indien",
  Indonesia: "Indonesien",
  Iran: "Iran",
  Iraq: "Irak",
  Ireland: "Irland",
  Israel: "Israel",
  Italy: "Italien",
  Jamaica: "Jamaica",
  Japan: "Japan",
  Jordan: "Jordanien",
  Kazakhstan: "Kazakstan",
  Kenya: "Kenya",
  Kiribati: "Kiribati",
  Kuwait: "Kuwait",
  Kyrgyzstan: "Kirgizistan",
  Laos: "Laos",
  Latvia: "Lettland",
  Lebanon: "Libanon",
  Lesotho: "Lesotho",
  Liberia: "Liberia",
  Libya: "Libyen",
  Liechtenstein: "Liechtenstein",
  Lithuania: "Litauen",
  Luxembourg: "Luxemburg",
  Madagascar: "Madagaskar",
  Malawi: "Malawi",
  Malaysia: "Malaysia",
  Maldives: "Maldiverna",
  Mali: "Mali",
  Malta: "Malta",
  "Marshall Islands": "Marshallöarna",
  Mauritania: "Mauretanien",
  Mauritius: "Mauritius",
  Mexico: "Mexiko",
  Micronesia: "Mikronesien",
  Moldova: "Moldavien",
  Monaco: "Monaco",
  Mongolia: "mongoliet",
  Montenegro: "Montenegro",
  Morocco: "Marocko",
  Mozambique: "Moçambique",
  "Myanmar (formerly Burma)": "Myanmar (tidigare Burma)",
  Namibia: "Namibia",
  Nauru: "Nauru",
  Nepal: "Nepal",
  Netherlands: "Nederländerna",
  "New Zealand": "Nya Zeeland",
  Nicaragua: "Nicaragua",
  Niger: "Niger",
  Nigeria: "Nigeria",
  "North Korea": "Nordkorea",
  "North Macedonia": "Nordmakedonien",
  Norway: "Norge",
  Oman: "oman",
  Pakistan: "Pakistan",
  Palau: "Palau",
  "Palestine State": "Palestina staten",
  Panama: "Panama",
  "Papua New Guinea": "Papua Nya Guinea",
  Paraguay: "Paraguay",
  Peru: "Peru",
  Philippines: "Filippinerna",
  Poland: "Polen",
  Portugal: "Portugal",
  Qatar: "Qatar",
  Romania: "Rumänien",
  Russia: "Ryssland",
  Rwanda: "Rwanda",
  "Saint Kitts and Nevis": "Saint Kitts och Nevis",
  "Saint Lucia": "Saint Lucia",
  "Saint Vincent and the Grenadines": "Saint Vincent och Grenadinerna",
  Samoa: "Samoa",
  "San Marino": "San Marino",
  "Sao Tome and Principe": "Sao Tomé och Principe",
  "Saudi Arabia": "Saudiarabien",
  Senegal: "Senegal",
  Serbia: "Serbien",
  Seychelles: "Seychellerna",
  "Sierra Leone": "Sierra Leone",
  Singapore: "Singapore",
  Slovakia: "Slovakien",
  Slovenia: "Slovenien",
  "Solomon Islands": "Salomonöarna",
  Somalia: "Somalia",
  "South Africa": "Sydafrika",
  "South Korea": "Sydkorea",
  "South Sudan": "södra Sudan",
  Spain: "Spanien",
  "Sri Lanka": "Sri Lanka",
  Sudan: "Sudan",
  Suriname: "Surinam",
  Sweden: "Sverige",
  Switzerland: "Schweiz",
  Syria: "Syrien",
  Tajikistan: "Tadzjikistan",
  Tanzania: "Tanzania",
  Thailand: "Thailand",
  "Timor-Leste": "Östtimor",
  Togo: "Togo",
  Tonga: "Tonga",
  "Trinidad and Tobago": "Trinidad och Tobago",
  Tunisia: "Tunisien",
  Turkey: "Kalkon",
  Turkmenistan: "Turkmenistan",
  Tuvalu: "Tuvalu",
  Uganda: "Uganda",
  Ukraine: "Ukraina",
  "United Arab Emirates": "Förenade arabemiraten",
  "United Kingdom": "Storbritannien",
  "United States of America": "Amerikas förenta stater",
  Uruguay: "Uruguay",
  Uzbekistan: "Uzbekistan",
  Vanuatu: "Vanuatu",
  Venezuela: "Venezuela",
  Vietnam: "Vietnam",
  Yemen: "Jemen",
  Zambia: "Zambia",
  Zimbabwe: "Zimbabwe",
};
