import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Cookies from "js-cookie";
import { initReactI18next } from "react-i18next";
import { translationEn, translationSv } from "./pages/Translations";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)

  .init({
    resources: {
      en: { translation: translationEn },
      sv: { translation: translationSv },
    },
    debug: false,
    lng: !Cookies.get("i18next") ? "sv" : null,
    fallbackLng: "sv",
    nonExplicitSupportedLngs: true,

    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["cookie", "htmlTag"],
      caches: ["cookie"],
    },
  });
