import requests from "./httpService";

const LoginServices = {
  setToken: async (code) => {
    return await requests.get(`/auth/callback?code=${code}`);
  },
  authRefreshToken: async (Token) => {
    return await requests.post(`/auth/refresh?refresh_token=${Token}`);
  },
  me: async () => {
    return await requests.get(`/auth/me`);
  },
  updateAccount: async ({ fullname, email }) => {
    return await requests.put(`/auth/updateAccount`, { fullname, email });
  },
};

export default LoginServices;
